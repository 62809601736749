import React, { useEffect, useState } from "react";
import DefaultAuthentication from "./DefaultAuthentication";
import { getUser, setUser, User, UserNotFound } from "../user/util";
import "./styles/Authentication.css";
import { getWorkbenchUrl } from '../configuration/ioeConfiguration';

export interface AuthenticationProperties {
  children: JSX.Element | JSX.Element[];
  onUserSet?: () => void;
}

const Authentication = (props: AuthenticationProperties) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [IOEChecked, setIOEChecked] = useState<boolean>(false);
  const [IOEPresent, setIOEPresent] = useState<boolean>(false);
  const [proceedWithAuthentication, setProceedWithAuthentication] = useState<
    boolean
  >(false);
  const [paramsProcessed, setParamsProcessed] = useState<boolean>(false);
  const [embedded, setEmbedded] = useState<boolean>(false);

  const handleSetAuthenticated = (value: boolean) => {
    setAuthenticated(value);
  };

  useEffect(() => {
    // read the params to get current user info
    const query = new URLSearchParams(window.location.search);
    const userName = query.get("user");
    const userId = query.get("userId");
    const sessionId = query.get("sessionId");
    if (userName && userId && sessionId) {
      setUser({ userId: userId, username: userName, sessionId: sessionId });
      setEmbedded(true);
    }
    setParamsProcessed(true);
  }, []);

  useEffect(() => {
    // attempt to reach IOE, if it fails then
    // skip any authentication
    fetch(`${getWorkbenchUrl()}/actuator/health`).then((res) => {
      if (!res.ok) {
        // IOE present? [not present]
        setAuthenticated(true);
      } else {
          const contentType = res.headers.get("content-type");
          if (!contentType?.includes("json")) {
            // no ioe, skip authentication for local dev
            setAuthenticated(true);
          } else {
            setIOEPresent(true);
          }
      }
      setIOEChecked(true);
    }).catch((e: Error) => {
      // IOE present? [not present]
      setAuthenticated(true);
      setIOEChecked(true);
    });
  }, []);

  useEffect(() => {
    if (IOEChecked && IOEPresent && paramsProcessed && !embedded) {
      // Existing user?
      const user = getUser();
      if (!embedded && (user as User).username !== undefined) {
        // [exists]
        // Here we do not request info from the user
        // we just ask IOE to recreate the User: Create User
        fetch(`${getWorkbenchUrl()}/core/users`, {
          body: JSON.stringify(user),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res.ok) {
            res.json().then((user) => {
              // store user object
              setUser(user);
              setAuthenticated(true);
              if (props.onUserSet) {
                props.onUserSet();
              }
            });
          }
        });
      } else {
        // [no]
        // Here we allow the authentication provider to proceed
        // with management: Create User
        setProceedWithAuthentication(true);
      }
    }
    if(embedded) {
      setAuthenticated(true);
    }
  }, [IOEChecked, IOEPresent, props, paramsProcessed, embedded]);

  useEffect(() => {
    /**
     * Handle session close, this covers browser close, tab close
     * and refresh
     */
    window.addEventListener("beforeunload", () => {
      // only watch for unload if app is not loaded in
      // an IOE client
      if(!window.parent) {
        const user = getUser();
        if ((user as UserNotFound).message === undefined) {
          fetch(
            `${getWorkbenchUrl()}/core/users/${(user as User).userId}/${(user as User).sessionId}`, {
              method: "DELETE"
            }
          );
        }
      }
    });
  }, []);

  if (!IOEChecked) {
    // wait for ioe check
    return null;
  }

  return authenticated && !proceedWithAuthentication ? (
    <div className="ioe-authenticated-container">{props.children}</div>
  ) : !authenticated && proceedWithAuthentication ? (
    <div className="ioe-authentication-container">
      <DefaultAuthentication
        onUserSet={props.onUserSet}
        setAuthenticated={handleSetAuthenticated}
        setProceedWithAuthentication={setProceedWithAuthentication}
      />
    </div>
  ) : null;
};
export default Authentication;
