import { createStore } from 'redux';
import reducers from './reducers';

declare const window: Record<string, unknown>;

export const dataStore = createStore(reducers);

if (window.ioe) {
  window.ioe = { ...(window.ioe as Record<string, any>), store: dataStore };
} else {
  window.ioe = { store: dataStore };
}
