import React from 'react';
import './FloatingMenu.css';

export interface FloatingMenuItem {
  eventId: string;
  label: string | JSX.Element;
  value?: string;
  disabled?: boolean;
  showSubItems?: boolean;
  subItems?: FloatingMenuItem[];
  onClick?: (
    eventId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: string
  ) => void;
  onMouseOver?: (
    eventId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: string
  ) => void;
  onMouseLeave?: (
    eventId: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: string
  ) => void;
}

export interface FloatingMenuProps {
  items: FloatingMenuItem[];
  onClickedItem: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: string
  ) => void;
  isSubmenu: boolean
}

const FloatingMenu = ({ items, onClickedItem, isSubmenu }: FloatingMenuProps) => {
  return (
    <nav
      className={`oa-w-auto oa-bg-tertiary oa-rounded-xl ${isSubmenu ? 'oa-absolute oa-z-51' : 'oa-absolute oa-top-full oa-left-0 oa-z-50 fm-nav'}`}
    >
      <ul className={`oa-border-secondary oa-border oa-rounded-xl oa-text-left oa-pb-0.6 oa-pl-0.8 oa-pt-0.8 oa-pr-0.8 oa-bg-tertiary-dark-700 oa-w-max`}>
        {items.map((item) => (
          <li key={item.eventId} className="fm-item">
            <button disabled={item.disabled}
              className="oa-w-full oa-text-secondary oa-p-1 enabled:hover:oa-text-black oa-whitespace-nowrap"
              onClick={(e) => {
                if (typeof onClickedItem === 'function') {
                  onClickedItem(e);
                }
                if(item.onClick) {
                  item.onClick(item.eventId, e, item.value);
                }
              }}
              onMouseOver={(e) => {
                if (item.onMouseOver) {
                  item.onMouseOver(item.eventId, e);
                }
              }}
              onMouseLeave={(e) => {
                if (item.onMouseLeave) {
                  item.onMouseLeave(item.eventId, e);
                }
              }}
            >
              {item.label}
            </button>
            {item.showSubItems && item.subItems ? 
              <div className="oa-w-0 oa-h-0 oa-relative oa-left-full oa-rounded-xl oa-bottom-[30px]">
                <FloatingMenu isSubmenu={true} onClickedItem={onClickedItem} items={item.subItems} />
              </div>: undefined}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FloatingMenu;
