import { useEffect } from 'react';
import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';
import { User } from '../generated/user/util';

/**
 * Provide access to the IOE data registry for
 * static imagery
 */
const useImagerySupport = (user: User | undefined) => {
  useEffect(() => {
    if (user) {
      const ioeWindow = window as Record<string, any>;
      if (!ioeWindow.ioe) {
        ioeWindow.ioe = {
          initialized: true,
        };
      }
      ioeWindow.ioe.imagery = {
        static: {
          getStaticImagery: async () => {
            return fetch(
              `${getWorkbenchUrl()}/core/users/${user.userId}/imagery/static`
            ).then((res) => {
              if (res.ok) {
                return res.json();
              }
            });
          },
        },
      };
    }
  }, [user]);
};
export default useImagerySupport;
