import '../../styles/deneir-wecop.css';
import { View } from '../../types/View';
import ViewContent from './ViewContent';
import ViewTab from './ViewTab';
import useTool from '../../hooks/useTool';
import { executeContextTool } from '../../tools/Execution';
import { WorkbenchConfiguration } from '../../types/WorkbenchConfiguration';
import { getUser, User, userFound, UserNotFound } from '../../generated/user/util';
import { useEffect, useState } from 'react';
import Tab from '$design-system/packages/react-design-system/src/components/tabs/Tab';
import Tabs from '$design-system/packages/react-design-system/src/components/tabs/Tabs';
import TabPanel from '$design-system/packages/react-design-system/src/components/tabs/TabPanel';
import TabState from '$design-system/packages/react-design-system/src/components/tabs/TabState';
import { getVariable } from '../../generated/configuration/ioeConfiguration';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: {
  view: View;
  containerId: string;
  configuration: WorkbenchConfiguration;
  openViewExternally: (user: User | UserNotFound | undefined, view: View) => void;
  activeTab: string;
  selectTabCallback: (tab: string) => void;
  showTab: boolean;
}) => {
  const { viewId, name, nextView, disabled } = props.view;
  const { containerId, configuration, openViewExternally, activeTab, selectTabCallback, showTab } = props;
  const [foundUser, setFoundUser] = useState<User | undefined>(undefined);
  const user = getUser();
  const hideTabView = getVariable('HIDE_VIEW_TABS');
  useEffect(() => {
    if (user && !foundUser) {
      if (userFound(user)) {
        setFoundUser(user as User);
      }
    }
  }, [user, foundUser]);

  const closeView = useTool({
    user: foundUser,
    tool: 'Close View',
    configuration: configuration,
  });

  const handleClose = (view: View) => {
    if (closeView) {
      executeContextTool(foundUser, closeView, [view.viewId, containerId]);
    }
  };

  const handleOpenExternal = (view: View) => {
    if (user && userFound(user)) {
      openViewExternally(user, view);
    }
  };

  const addTabs = (view: View, tabs: JSX.Element[]) => {
    tabs.push(
      <Tab tab_id={view.viewId} key={view.viewId}>
        <ViewTab
          closeEnabled={closeView !== undefined}
          handleClose={handleClose}
          handleOpenExternal={handleOpenExternal}
          {...view}
        />
      </Tab>
    );
    if (view.nextView && view.nextView.disabled !== true) {
      addTabs(view.nextView, tabs);
    }
    return tabs;
  };

  const addPanels = (view: View, panels: JSX.Element[]) => {
    if (view) {
      panels.push(
        <TabPanel panel_id={view.viewId} key={view.viewId} styling={{ height: 'calc(100% - 45px)' }}>
          <ViewContent {...view} />
        </TabPanel>
      );
      if (view.nextView) {
        addPanels(view.nextView, panels);
      }
    }
    return panels;
  };

  if (disabled === true) {
    return null;
  }

  return (
    <div key={name} className="deneir-wecop-ui--view">
      <TabState initialTab={viewId} currentTab={activeTab} setCurrentTab={selectTabCallback}>
        {hideTabView || !showTab ? null : (
          <Tabs>
            <Tab tab_id={viewId} key={viewId}>
              <ViewTab
                closeEnabled={closeView !== undefined}
                handleClose={handleClose}
                handleOpenExternal={handleOpenExternal}
                {...props.view}
              />
            </Tab>
            {nextView ? addTabs(nextView, []) : undefined}
          </Tabs>
        )}
        <TabPanel
          panel_id={viewId}
          key={viewId}
          styling={hideTabView || !showTab ? undefined : { height: 'calc(100% - 45px)' }}
        >
          <ViewContent {...props.view} />
        </TabPanel>
        {nextView ? addPanels(nextView, []) : undefined}
      </TabState>
    </div>
  );
};
