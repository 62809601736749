import React, { PropsWithChildren, useEffect, useState } from 'react';

export interface TableTabProperties {
  initialTab: string;
  currentTab?: string;
  setCurrentTab?: (current: string) => void;
}

const TabState = (
  props: TableTabProperties & PropsWithChildren<unknown>
): any => {
  const { children, initialTab, currentTab, setCurrentTab } = props;
  const [selected, _setSelected] = useState<string>(initialTab);

  useEffect(() => {
    _setSelected(initialTab);
  }, [initialTab]);

  useEffect(() => {
    if (currentTab) {
      _setSelected(currentTab);
    }
  }, [currentTab]);

  const setSelected = (tab: string) => {
    if (setCurrentTab) {
      setCurrentTab(tab);
    }
    _setSelected(tab);
  };

  const controlled = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as any, {
        selected: selected,
        setSelected: setSelected,
      });
    }
  });
  return controlled ? controlled : null;
};

export default TabState;
