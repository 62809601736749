import { ClosureRequest } from '../ClosureRequest';
import { MessageRequest } from '../common/MessageRequest';

export const requestClosureMessage = (
  view: ClosureRequest
): MessageRequest<ClosureRequest> => {
  return {
    sender: 'IOE Workbench',
    signal: 'requestClosure',
    data: view,
  };
};
