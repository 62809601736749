const MODAL_ATTR_VISIBLE = 'visible';
const MODAL_ATTR_BACKDROP = 'disable-backdrop';
const MODAL_ATTR_TOP = 'top';
const MODAL_ATTR_LEFT = 'left';
const MODAL_WRAPPER_CLASS = 'oa-modal-wrapper';
const MODAL_ATTR_WIDTH = 'width';

export class Modal extends HTMLElement {
  constructor() {
    super();
  }

  get visible() {
    const visible = this.getAttribute(MODAL_ATTR_VISIBLE);
    return visible === 'true';
  }

  set visible(value) {
    value ? this.removeAttribute(MODAL_ATTR_VISIBLE) : this.setAttribute(MODAL_ATTR_VISIBLE, '');
  }

  get disableBackdrop() {
    return this.hasAttribute(MODAL_ATTR_BACKDROP);
  }

  get top() {
    return this.getAttribute(MODAL_ATTR_TOP);
  }

  get left() {
    return this.getAttribute(MODAL_ATTR_LEFT);
  }

  get width() {
    return this.getAttribute(MODAL_ATTR_WIDTH);
  }

  get dynamicModalStyles() {
    const top = this.top ? `${this.top}%` : undefined;
    const left = this.left ? `${this.left}%` : undefined;
    const width = this.width ? `${this.width}` : undefined;
    const style = `
    ${width ? `width: ${width}px;` : ''}
    ${top ? `top: ${top};` : 'top: 50%;'}
    ${left ? `left: ${left};` : 'left: 50%;'}
    ${
      left && top ? `transform: translate(-${left}, -${top});` : 'transform: translate(-50%, -50%);'
    }
    `;
    return style.trim();
  }

  get dynamicWrapperStyles() {
    const style = `
    ${
      this.disableBackdrop
        ? 'background-color: undefined;'
        : 'background-color: var(--oa-modal-backdrop-rgba, rgba(128, 128, 128, .4)'
    }
    `;
    return style.trim();
  }

  get staticStyles() {
    return `
    .oa-modal-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      z-index: 1050;
    }
    .oa-modal-visible {
      opacity: 1;
      visibility: visible;
    }
    .oa-modal {
      position: absolute;
      background-color: var(--oa-modal-bg, var(--color-background-surface-default));
      color: white;
      min-width: 100px;
      min-height: 50px;
    }
    `;
  }

  get element() {
    const wrapperClass = this.visible ? 'oa-modal-wrapper oa-modal-visible' : 'oa-modal-wrapper';
    return `
  <div style="${this.dynamicWrapperStyles}" class='${wrapperClass}'>
    <section style="${this.dynamicModalStyles}" class='oa-modal'>
      <slot name="content"></slot>
    </section>
  </div>
    `;
  }

  static get observedAttributes() {
    return ['visible'];
  }

  connectedCallback() {
    this.render();
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.shadowRoot) {
      const wrapper = this.shadowRoot.querySelector(`.${MODAL_WRAPPER_CLASS}`);
      if (name === 'visible' && wrapper) {
        if (newValue !== 'true') {
          wrapper.classList.remove('oa-modal-visible');
        } else {
          wrapper.classList.add('oa-modal-visible');
        }
      }
    }
  }

  createLocalStyle() {
    const style = document.createElement('style');
    style.textContent = this.staticStyles;
    return style;
  }

  render() {
    const style = this.createLocalStyle();
    const container = document.createElement('section');
    container.innerHTML = this.element;
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(container);
    shadowRoot.appendChild(style);
  }
}

// load the web-component
window.customElements.define('oa-modal', Modal);
