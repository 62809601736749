import React, { PropsWithChildren } from 'react';

export interface TabProperties {
  tab_id: string;
}

const Tab = (props: TabProperties & PropsWithChildren<unknown>) => {
  const { children } = props;
  return <>{children}</>;
};

export default Tab;
