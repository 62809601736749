import { Direction } from '../common/Direction';
import { MessageRequest } from '../common/MessageRequest';
import { CommunicationSystemPost } from './CommunicationSystemPost';
import { connectToModelStream } from './CoreMessages';
import { getUser, User, UserNotFound } from "../user/util";
import { getWorkbenchUrl } from '../configuration/ioeConfiguration';

export const CommunicationSystem = (
  direction: Direction
): BroadcastChannel | CommunicationSystemPost => {
  if (direction === Direction.IN) {
    // Use micronnect to pull the "Communication" events from
    // the SSE stream
    const commBus = connectToModelStream();
    commBus?.addChannel('comm-messages', {
      source: 'core-messages',
      streamName: 'Communication',
    });
    return new BroadcastChannel('comm-messages');
  } else {
    return {
      postMessage: <T>(event: MessageRequest<T>) => {
        const user = getUser();
        if((user as UserNotFound).message === undefined) {
          fetch(
            `${getWorkbenchUrl()}/core/communications/message/${(user as User).userId}`,
            {
              body: JSON.stringify(event),
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
        }
      },
    } as CommunicationSystemPost;
  }
};
