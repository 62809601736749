import usePlatformExtension from '../../extensions/usePlatformExtension';
import './styles/WorkbenchBar.css';
import ApplicationInFrame from '../views/ApplicationInFrame';

interface WorkbenchClassificationBarProps {
  extensionId: string;
}

const WorkbenchClassificationBar = (props: WorkbenchClassificationBarProps) => {
  const { extensionId } = props;
  const bannerId = extensionId.split('::')[1]
  const { extensionServer } = usePlatformExtension(extensionId, `${bannerId}-point`);
  let inFrame = undefined;
  if (extensionServer) {
    inFrame = <ApplicationInFrame
      name="classification-banner"
      applicationId="workbench-frontend"
      server={extensionServer}
    />
  }
  return (
    <div id={`${bannerId}-point`} className='ioe-workbench--classification-banner'>
      {inFrame ? inFrame : <div id={bannerId} />}
    </div>
  );
}

export default WorkbenchClassificationBar;