
const AboutModal = ({ isModalOpen, setIsModalOpen, product }) => {
  return (
    <oa-modal visible={isModalOpen}>
      <div slot="content" className="dialog" style={{ width: '550px' }}>
        <header className="header">
          <h1>About {product?.name}</h1>
        </header>
        <section className="body">
          <div id="attributions-point" />
          <br />
          <p>
            {product?.name} Version {product?.version}
          </p>
        </section>
        <footer className="footer">
          <button type="button" className="primary lg" onClick={() => setIsModalOpen(false)}>
            Close
          </button>
        </footer>
      </div>
    </oa-modal>
  );
}

export default AboutModal;