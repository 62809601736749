import { createBus } from 'micronnect';
import { CommunicationBus } from 'micronnect/dist/bus/CommunicationBus';
import { CommunicationBusResult } from 'micronnect/dist/results/CommunicationBusResult';
import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';

export const connectToModelStream = (
  sessionId: string
): CommunicationBus | undefined => {
  const ID = 'core-messages';
  let CoreMessageBusResult: CommunicationBusResult | undefined = undefined;
  if (!CoreMessageBusResult) {
    CoreMessageBusResult = createBus(
      ID,
      {
        streams: [
          {
            id: 'core-messages',
            server: `${getWorkbenchUrl()}/core/out/${sessionId}`,
          },
        ],
      },
      getWorkbenchUrl()
    );
  }
  (window as Record<string, any>).ioe = {
    ...(window as Record<string, any>).ioe,
    CoreMessageBus: CoreMessageBusResult.bus,
  };
  return CoreMessageBusResult.bus;
};
