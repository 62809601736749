import { Action } from '../../types/Action';

import { WorkbenchConfiguration } from '../../types/WorkbenchConfiguration';

export const SETCONFIGURATION = '_setconfiguration';
export const setConfiguration = (
  configuration: WorkbenchConfiguration
): Action<WorkbenchConfiguration> => {
  return {
    type: SETCONFIGURATION,
    payload: configuration,
  };
};
