import { useEffect, useState } from 'react';
import { ApplicationStatusObject} from '../ApplicationStatusObject';

import { MessageRequest } from '../common/MessageRequest';
import { ApplicationStatusChannel } from './ApplicationStatusChannel';
import { CommunicationSystem } from '../communicationSystem/CommunicationSystem';
import { Direction } from '../common/Direction';

/**
 * Incoming signals
 */
export const useApplicationStatusWiringIncoming = () => {
  const [last_applicationstatusReceived, setLast_applicationstatusReceived] =
    useState<ApplicationStatusObject>();


  const _ApplicationStatusChannel: ApplicationStatusChannel = {
    id: 'ApplicationStatus'
  }

  const getChannel = (id: string): BroadcastChannel | Window => {
    // some browsers do not support BroadcastAPI
    // in those cases we use the window object
    let newChannel: BroadcastChannel | Window | undefined = undefined;
    if (window.BroadcastChannel) {
      // Broadcast API indicates that if a channel is already
      // created it will be used, therefore we do not require
      // managing an instance with this hooks state
      newChannel = new BroadcastChannel(id);
    } else {
      newChannel = window;
    }
    return newChannel;
  };

  const isExpectedSignal = (
    signal: string,
    message: MessageRequest<unknown>
  ): message is MessageRequest<unknown> => {
    if (
      (message as MessageRequest<unknown>).signal !== undefined &&
      message.signal === signal
    ) {
      return true;
    }
    return false;
  };

  const handleMessage = (event: MessageEvent) => {
      const data = event.data;
            if(isExpectedSignal('ApplicationStatus', data)) {
        const message = data as MessageRequest<ApplicationStatusObject>;
		    setLast_applicationstatusReceived(message.data);
      }

  }

  useEffect(() => {
    getChannel(_ApplicationStatusChannel.id).onmessage = handleMessage;
  }, []);


  return {
        last_applicationstatusReceived,

  }
}
