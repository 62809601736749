import React from 'react';

export interface GlobalStatusBarAppInfoProps {
  label: string;
  username: string;
}

const GlobalStatusBarAppInfo = ({ label, username }: GlobalStatusBarAppInfoProps) => (
  <div className="oa-flex oa-flex-col oa-gap-px oa-justify-center">
    <h1 className="oa-text-[30px] oa-font-bold">{label}</h1>
    <p className="oa-text-left">{username}</p>
  </div>
);

export default GlobalStatusBarAppInfo;
