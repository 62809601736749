import { FC } from "react";

interface MessageModalProps {
  isModalOpen: boolean,
  title: string,
  message: string,
  buttonText: string,
  buttonCallback: () => void;
}

const MessageModal: FC<MessageModalProps> = ({ isModalOpen, title, message, buttonText, buttonCallback }) => {
  return (
    <oa-modal visible={isModalOpen}>
      <div slot="content" className="dialog" style={{ width: '550px' }}>
        <header className="header">
          <h1>{title}</h1>
        </header>
        <section className="body">
          <p>
            {message}
          </p>
        </section>
        <footer className="footer">
          <button type="button" className="primary lg" onClick={() => buttonCallback()}>
            {buttonText}
          </button>
        </footer>
      </div>
    </oa-modal>
  );
}

export default MessageModal;