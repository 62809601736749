import { Dispatch, useEffect, useRef, useState } from 'react';
import { useApplicationStatusWiringIncoming } from '../generated/signals/useApplicationStatusWiring';
import { getVariable } from '../generated/configuration/ioeConfiguration';
import { setFailedApplications } from '../store/actions/FailedApplicationsActions';
import { connectToModelStream } from '../communications/CoreMessages';
import { Action } from '../types/Action';

interface AppReadyStatePerspective {
  loadingScreenEnabled?: boolean;
  loadingScreenTimeout_ms?: number;
  applications?: Record<string, boolean>;
}

export const useLoadingScreen = (sessionId: string | undefined, perspective: string, dispatch: Dispatch<Action>) => {
  const { last_applicationstatusReceived } = useApplicationStatusWiringIncoming();
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean | undefined>(true);
  const [readySignals, setReadySignals] = useState<Record<string, boolean>>({});
  const [applicationReadyDictionary, setApplicationReadyDictionary] = useState<unknown | undefined>(undefined);
  const [perspectiveDictionary, setPerspectiveDictionary] = useState<AppReadyStatePerspective | undefined>(undefined);
  const [loadingScreenEnabled, setLoadingScreenEnabled] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [timedOut, setTimedOut] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number | undefined>(undefined);
  const [closeBroadcastChannel, setCloseBroadcastChannel] = useState<boolean>(false);

  const noLoadingScreen = useRef<Boolean>(true);
  const timer = useRef(undefined);
  const timeoutTimeMS = useRef<number>(25000);
  const channel = useRef<BroadcastChannel | undefined>(undefined);
  const loadingScreenRemoved = useRef<boolean>(false);

  // adding channel to listen for data system time
  // do not remove loading screen until we have recieved time
  if (sessionId) {
    const CoreMessageBus = connectToModelStream(sessionId);
    if (CoreMessageBus) {
      CoreMessageBus.addChannel("wait-for-datasystem-time", {
        source: CoreMessageBus.id,
        streamName: "datasystem-clock",
        dataId: "datasystem-clock"
      });
    }
  }

  /**
   * Expected applications to provide ready signals defined for each
   * perspective. Object set in docker-stack's configuration.json file
   * with key "APP_READY_STATE". Perspective applications with a false boolean
   * value will indicate the application should wait for a ready signal. A true boolean
   * value will indicate apathy about its ready status.
   * NOTE: In the case of the APP_READY_STATE shown below, a 4th perspective PERSPECTIVE_D
   * could exist but no loading screen would show. 
   * Format should follow:
   *  "APP_READY_STATE": {
        "Perspective_A": {
          "loadingScreenEnabled": true,
          "loadingScreenTimeout_ms": 15000,
          "applications": {
            "application-1": false,
            "application-2": false
          }
        },
        "Perspective_B": {
          "loadingScreenEnabled": false,
          "loadingScreenTimeout_ms": 25000,
          "applications": {
            "application-1": false,
            "application-2": false,
            "application-3": false,
            "application-4": false
          }
        },
        "Perspective_C": {
          "loadingScreenEnabled": true,
          "loadingScreenTimeout_ms": 50000,
          "applications": {
            "application-1": false
          }
        }
      }
   */
  useEffect(() => {
    // add event listener to listen for initial time from datasystem
    // if we have already gotten the time or a channel already exists
    // we do not want to open another listener
    if (!currentTime && !channel.current) {
      channel.current = new BroadcastChannel("wait-for-datasystem-time");
      channel.current.addEventListener("message", (event) => {
        if (event.data && event.data.content) {
          setCurrentTime(event.data.content);
          setCloseBroadcastChannel(true);
        }
      });
    }
  }, [currentTime])

  useEffect(() => {
    // once we get the current time close the channel
    if (closeBroadcastChannel && channel.current && channel.current?.name === "wait-for-datasystem-time") {
      channel.current.close();
      channel.current = undefined;
      setCloseBroadcastChannel(false);
    }
  }, [closeBroadcastChannel])

  useEffect(() => {
    // get loading screen configuration
    const appReadyState = getVariable("APP_READY_STATE");
    if (appReadyState && !applicationReadyDictionary) {
      setApplicationReadyDictionary(appReadyState);
    }
    if (!appReadyState) {
      setShowLoadingScreen(false);
      setLoadingScreenEnabled(false);
    }
  }, [perspective, applicationReadyDictionary]);

  useEffect(() => {
    // start things up when perspective changes
    if (applicationReadyDictionary && perspective) {
      setTimedOut(false);
      if (applicationReadyDictionary[perspective]) {
        setPerspectiveDictionary(applicationReadyDictionary[perspective]);
        setIsReady(false);
        loadingScreenRemoved.current = false;
        noLoadingScreen.current = false;
      } else {
        setReadySignals(undefined);
        setPerspectiveDictionary(undefined);
        if (showLoadingScreen) {
          setShowLoadingScreen(false);
        } else {
          setShowLoadingScreen(showLoadingScreen === false ? undefined : false);
        }
        setLoadingScreenEnabled(false);
        noLoadingScreen.current = true;
      }
    }
    // reason for the the eslint comment below is due to showLoadingScreen
    // it needs to change from false to undefined but do
    // not want this hook to render when showLoadingScreen changes
    // this is due to if it already false and then setting it to false
    // will not cause a re-render and the loading screen will not be consistent
    // having the variable change from false to undefined forces a re-render
    // while keeping the downstream logic the same

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationReadyDictionary, perspective])

  useEffect(() => {
    // after perspective change we will get a new perspective dictionary
    // based on the APP_READY_STATE configuration for that perspective
    // if the loading is opted in (has configuration and `loadingScreenEnabled` is true)
    // the loading screen will show and will be watching for signals based on config
    // if the loading screen is opted out we will not show the loading screen and 
    // disregard the signals coming in
    if (perspectiveDictionary && !noLoadingScreen.current) {
      setLoadingScreenEnabled(perspectiveDictionary.loadingScreenEnabled);
      if (perspectiveDictionary.loadingScreenEnabled) {
        timeoutTimeMS.current = perspectiveDictionary.loadingScreenTimeout_ms
        setShowLoadingScreen(true);
        setReadySignals(perspectiveDictionary.applications);
      } else {
        setShowLoadingScreen(false);
      }
    }
  }, [perspectiveDictionary]);

  useEffect(() => {
    // recieved an application's ready signal
    // make sure it is defined and apart of perspective
    // then add it to the ready signal away with a ready status
    if (
      last_applicationstatusReceived &&
      perspectiveDictionary &&
      Object.keys(perspectiveDictionary.applications).includes(last_applicationstatusReceived.application)
    ) {
      setReadySignals((value) => {
        return {
          ...value,
          [last_applicationstatusReceived.application]:
            last_applicationstatusReceived.applicationStatus,
        };
      });
    }
  }, [last_applicationstatusReceived, perspectiveDictionary]);

  useEffect(() => {
    // check that all ready signals have been recieved
    // if all ready signals have been recieved with a
    // positive ready status set isReady to true
    if (!readySignals) return;
    const values = Object.values(readySignals);
    if (values.length < 1) {
      setIsReady(false);
    } else {
      setIsReady(values.every((condition) => condition));
    }
  }, [readySignals])

  useEffect(() => {
    // ensuring that all needed variables exist
    // if they exist, turn on loading screen until we recieve all necessary signals
    // a timeout is needed incase not all ready signals are recieved within a time limit
    if (perspectiveDictionary && !noLoadingScreen.current && perspectiveDictionary.loadingScreenEnabled) {
      if (isReady && currentTime) {
        setShowLoadingScreen(false);
        loadingScreenRemoved.current = true
      }
      if (!isReady && !loadingScreenRemoved.current) {
        setShowLoadingScreen(true);
        if (!timer.current) {
          // configured time in ms seconds have passed, remove loading overlay and render app
          timer.current = setTimeout(() => {
            // remove loading screen
            setTimedOut(true);
            setShowLoadingScreen(false);
          }, timeoutTimeMS.current);
        }
      }
    }
  }, [isReady, perspectiveDictionary, currentTime]);

  useEffect(() => {
    // clearing a timer when all ready signals were recieved
    if (timer.current && !showLoadingScreen) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }, [showLoadingScreen])

  useEffect(() => {
    // if we timeout, get the application that a ready signal was not
    // recieved from. Since we did not recieve a ready signal, allow the
    // user to know that this application has failed
    if (timedOut) {
      let failedApplications: string[] = [];
      Object.entries(readySignals).forEach(([app, condition]) => {
        if (!condition) {
          failedApplications.push(app);
        }
      });
      dispatch(setFailedApplications(failedApplications));
    }
  }, [timedOut, readySignals, dispatch])

  return {
    showLoadingScreen,
    setShowLoadingScreen,
    loadingScreenEnabled,
    setLoadingScreenEnabled
  };
};
