import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WorkbenchRoot } from '../types/WorkbenchRoot';
import { useDispatch } from 'react-redux';
import { useViewManagementWiringIncoming } from '../generated/signals/useViewManagementWiring';
import { useViewTabManagementWiringIncoming } from '../generated/signals/useViewTabManagementWiring';
import { View } from '../types/View';
import { setConfiguration } from '../store/actions/WorkbenchActions';
import { addOrRemoveView, findContainerAndView } from './perspectiveUtils';
import { ConfigureActiveTab } from 'src/generated/ConfigureActiveTab';

const usePerspectiveInterfaces = () => {
  const { last_viewConfigurationReceived } = useViewManagementWiringIncoming();
  const { last_viewTabConfigurationReceived } = useViewTabManagementWiringIncoming();
  const [availableViews, setAvailableViews] = useState<View[]>([]);
  const currentConfiguration = useSelector(
    (state: WorkbenchRoot) => state.workbench.WorkbenchConfiguration
  );
  const [viewToActivate, setViewToActivate] = useState<ConfigureActiveTab>(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    fetch('/workbench/core/views').then((result) => {
      if (result.ok) {
        result.json().then((views) => {
          setAvailableViews(views);
        });
      } else {
        console.error(`Unable to fetch available views. Reason: ${result.status}`);
      }
    });
  }, []);

  useEffect(() => {
    if (last_viewConfigurationReceived && availableViews.length > 0) {
      const view = findContainerAndView(
        last_viewConfigurationReceived.viewName,
        currentConfiguration.activePerspective.rootContainer
      );
      if (!view) {
        const newConfiguration = { ...currentConfiguration };
        addOrRemoveView(newConfiguration, last_viewConfigurationReceived, availableViews);
        dispatch(setConfiguration(newConfiguration)
        );
      }
    }
  }, [availableViews, currentConfiguration, dispatch, last_viewConfigurationReceived]);

  useEffect(() => {
    if (last_viewTabConfigurationReceived) {
      setViewToActivate(last_viewTabConfigurationReceived);
    }
  }, [last_viewTabConfigurationReceived]);
  return {
    viewToActivate,
  };
};
export default usePerspectiveInterfaces;
