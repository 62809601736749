import { PropsWithChildren, useContext, useRef } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import FloatingMenu, {
  FloatingMenuItem,
} from '$design-system/packages/react-design-system/src/components/menu/FloatingMenu';
import './GlobalStatusBarLogo.css';
import { MenuContext } from '../../../context/Menu';

export interface GlobalStatusBarLogoProps {
  hasOpenMenu?: boolean;
  menuItems?: FloatingMenuItem[];
}
/* for some reason using a CSS module here makes the SVG smoother, as opposed to the direct class */
const GlobalStatusBarLogo = ({
  children,
  menuItems,
  hasOpenMenu = false,
}: PropsWithChildren<GlobalStatusBarLogoProps>) => {
  const { mainMenuOpen, setMainMenuOpen } = useContext(MenuContext)
  if (hasOpenMenu) setMainMenuOpen(true);

  const headerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = () => {
    setMainMenuOpen(false);
  };

  useOutsideAlerter(headerRef, handleOutsideClick);

  return (
    <div
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setMainMenuOpen(false);
        }
      }}
      ref={headerRef}
      className="oa-relative"
    >
      <button
        type="button"
        onClick={() => {
          setMainMenuOpen(!mainMenuOpen);
          headerRef.current.focus();
        }}
        className="oa-w-[40px] oa-h-full oa-flex oa-items-center oa-p-0 oa-justify-end hover:enabled:oa-bg-transparent"
      >
        {children}
      </button>
      {mainMenuOpen && menuItems != null ? (
        <FloatingMenu isSubmenu={false} onClickedItem={handleOutsideClick} items={menuItems} />
      ) : null}
    </div>
  );
};

export default GlobalStatusBarLogo;
