import { ReactNode, useEffect, useRef, useState } from 'react';
import './styles/Workbench.css';
import ContainerImpl from './components/perspectives/Container';
import { Container } from './types/Container';
import { Perspective } from './types/Perspective';
import { useDispatch, useSelector } from 'react-redux';
import { setConfiguration } from './store/actions/WorkbenchActions';
import { WorkbenchRoot } from './types/WorkbenchRoot';
import './communications/CoreMessages';

import ViewSelector from './components/views/ViewSelector';
import useConfigurationUpdates from './hooks/useConfigurationUpdates';
import { View } from './types/View';
import useImagerySupport from './support/useImagerySupport';
import useExtensionSupport from './support/useExtensionSupport';
import WorkbenchTopBar from './components/workbenchBars/WorkbenchTopBar';
import useAvailableStreams from './support/useAvailableStreamSupport';
import reportWebVitals from './reportWebVitals';
import useExternally from './hooks/useExternally';
import WorkbenchBottomBar from './components/workbenchBars/WorkbenchBottomBar';
import { getUser, User, userFound } from './generated/user/util';
import { getWorkbenchUrl } from './generated/configuration/ioeConfiguration';
import WorkbenchClassificationBar from './components/workbenchBars/WorkbenchClassificationBar';
import LoadingSplash from './components/LoadingSplash/LoadingSplash';
import usePerspectiveInterfaces from './support/usePerspectiveInterfaces';
import { useLoadingScreen } from './hooks/useLoadingScreen';
import usePlatformExtension from './extensions/usePlatformExtension';
import ApplicationInFrame from './components/views/ApplicationInFrame';
import { getVariable } from './generated/configuration/ioeConfiguration';

const ioeWindow = window as Record<string, any>;
if (!ioeWindow.ioe?.initialized) {
  // if we already have some ioe content
  if (ioeWindow.ioe) {
    ioeWindow.ioe = {
      ...ioeWindow.ioe,
      initialized: true,
    };
  } else {
    // otherwise create now
    ioeWindow.ioe = {
      initialized: true,
    };
  }
}

const Workbench = () => {
  const dispatch = useDispatch();
  const [containerContext, setContainerContext] = useState<Container | undefined>(undefined);
  const [show, setShow] = useState<boolean>(false);
  const [foundUser, setFoundUser] = useState<User | undefined>();
  const [perspective, setPerspective] = useState<string>('');
  const playbackMode: boolean =
    getVariable('STREAMING_MODE') && getVariable('STREAMING_MODE').toLowerCase() === 'playback'
      ? true
      : false;

  const user = getUser();
  if (!foundUser && userFound(user)) {
    setFoundUser(user as User);
  }

  const currentConfiguration = useSelector(
    (state: WorkbenchRoot) => state.workbench.WorkbenchConfiguration
  );

  const { extensionServer: topBarOverrideServer } = usePlatformExtension(
    'topBar::override',
    'ioe-top-bar'
  );

  let topBarOverride: ReactNode = undefined;
  if (topBarOverrideServer !== '') {
    topBarOverride = (
      <ApplicationInFrame
        name="top-bar-override"
        applicationId="workbench-frontend"
        server={topBarOverrideServer}
      />
    );
  }

  useEffect(() => {
    setPerspective(currentConfiguration?.activePerspective?.name);
  }, [currentConfiguration]);

  const { showLoadingScreen, setShowLoadingScreen, loadingScreenEnabled, setLoadingScreenEnabled } =
    useLoadingScreen(foundUser ? foundUser.sessionId : undefined, perspective, dispatch);

  useConfigurationUpdates(foundUser ? foundUser.sessionId : undefined, showLoadingScreen);

  const container = currentConfiguration?.activePerspective?.rootContainer;

  const { openViewExternally, openedExternally, requestRestoreView } = useExternally(foundUser);

  const root = useRef<HTMLDivElement>(null);

  /**
   * Setup support functions for IOE access
   */
  useImagerySupport(foundUser);
  useExtensionSupport(foundUser);
  useAvailableStreams(foundUser);
  const { viewToActivate } = usePerspectiveInterfaces();

  useEffect(() => {
    if (foundUser) {
      fetch(`${getWorkbenchUrl()}/core/users/${foundUser.userId}/configuration`).then((res) => {
        if (res.ok) {
          res.json().then((config) => {
            dispatch(
              setConfiguration({
                ...config,
                activePerspective: config.perspectives.find(
                  (p: Perspective) => p.perspectiveId === config.activePerspectiveId
                ),
              })
            );
          });
        }
      });
      fetch(`${getWorkbenchUrl()}/core/users/${foundUser.userId}/views/system`).then((res) => {
        if (res.ok) {
          res.json().then((views: View[]) => {
            views.forEach((v) => {
              if (v.module) {
                import(/* webpackIgnore: true */ v.module)
                  .then(() => {
                    const moduleName = `${(
                      v.name.charAt(0).toLowerCase() + v.name.slice(1)
                    ).replaceAll(' ', '')}`;
                    const sysViewContainer = document.createElement('div');
                    sysViewContainer.id = moduleName;
                    document.body.appendChild(sysViewContainer);
                    (window as Record<string, any>)[moduleName].load(sysViewContainer);
                  })
                  .catch((e) => console.error('Error while requesting dynamic module', e));
              }
            });
          });
        }
      });
    }
  }, [dispatch, foundUser]);

  return (
    <>
      <LoadingSplash isLoading={showLoadingScreen} enabled={loadingScreenEnabled}>
        <h1>Loading the Globe...</h1>
        <p>This usually takes less than 15 seconds.</p>
      </LoadingSplash>
      <div className={`ioe-workbench ${playbackMode ? 'playback' : ''}`}>
      <WorkbenchClassificationBar extensionId="classificationBar::classification-banner"/>
        {topBarOverride ? (
          <div id="ioe-top-bar" className="ioe-workbench--top-bar ">
            {topBarOverride}
          </div>
        ) : (
          <WorkbenchTopBar
            setLoadingScreenEnabled={setLoadingScreenEnabled}
            restoreView={requestRestoreView}
            externalViews={openedExternally}
            configuration={currentConfiguration}
            setShowLoadingScreen={setShowLoadingScreen}
            setPerspective={setPerspective}
          />
        )}
        <div ref={root} className="ioe-workbench--root">
          {container ? (
            <>
              <ViewSelector
                show={show}
                setShow={setShow}
                containerContext={containerContext}
                configuration={currentConfiguration}
              />
              <ContainerImpl
                rootContainer={container}
                {...container}
                setShow={setShow}
                setContainerContext={setContainerContext}
                openViewExternally={openViewExternally}
                externalViews={openedExternally.map((vh) => vh.view)}
                restoreView={requestRestoreView}
                viewToActivate={viewToActivate}
              />
            </>
          ) : (
            <div>No Configuration.</div>
          )}
        </div>
        <WorkbenchBottomBar />
        <WorkbenchClassificationBar extensionId="classificationBottomBar::classification-bottom-banner"/>
      </div>
    </>
  );
};

reportWebVitals(console.log);
export default Workbench;
