import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';
import { User } from '../generated/user/util';
import { Tool } from '../types/Tool';

export const executeContextTool = (
  user: User | undefined,
  tool: Tool,
  context: string[]
) => {
  if (user) {
    fetch(`${getWorkbenchUrl()}/core/tools/${user.userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...tool,
        context: {
          parameters: context,
        },
      } as Tool),
    });
  }
};
