/**
 * Each app, and the workbench frontend will have access
 * to an endpoint /configuration.  At a minimum this must
 * have WORKBENCH_URL.
 */
export interface ConfigurationResult {
  error: string;
  ioeConfiguration: Record<string, any>;
}

// global hook, capture state once an reuse
let applicationConfiguration: ConfigurationResult = {
  ioeConfiguration: {
    WORKBENCH_URL: undefined,
  },
  error: ""
};
let error = "";

// Have to add sensible defaults here, because of the `getProduct` function's return type.
let product: ProductInfo = {
  name: 'Integrated Operations Environment',
  version: '1.0.0',
  branding_url: '/branding',
  configuration: {
    useDataTime: false,
  }
}

const getIOEConfiguration = async (): Promise<ConfigurationResult> => {
  return fetch("/configuration.json").then((result) => {
    if (result.ok) {
      const contentType = result.headers.get("content-type");
      // locally run apps may not have a configuration.json
      // but will return the pages html
      if (!contentType?.includes("application/json")) {
        return {
          error: "Could not find configuration file.",
          ioeConfiguration: {},
        };
      }
      return result.json().then((config) => {
        return {
          ioeConfiguration: config,
          error: "",
        };
      });
    } else {
      return { ioeConfiguration: {}, error: result.statusText };
    }
  });
};

/**
 * Get the Runtime Configuration
 *
 * Looks to see if the ioeConfiguration contains the `WORKBENCH_URL` if not it will
 * request a fresh runtime configuration from the API.
 *
 * @returns a {@link Promise} that contains either the cached {@link ConfigurationResult} or fresh data.
 */
export const getRuntimeConfiguration = async (): Promise<ConfigurationResult> => {
  if (!applicationConfiguration.ioeConfiguration.WORKBENCH_URL) {
    applicationConfiguration = await getIOEConfiguration();
  }
  return Promise.resolve(applicationConfiguration);
};

export const updateAndGetRuntimeConfiguration = async (): Promise<ConfigurationResult> => {
  const result = await getIOEConfiguration();
  if (result.error === "") {
    applicationConfiguration = result;
  }
  return applicationConfiguration;
};

export const getVariable = (key: string): string => {
  const ioeWindow = window as Record<string, any>;
  if(ioeWindow.ioe && ioeWindow.ioe[key]) {
    return ioeWindow.ioe[key];
  }
  return applicationConfiguration.ioeConfiguration[key];
};

export interface MainMenuLayoutSubMenuItem {
  name: string;
  url?: string;
}

export interface MainMenuLayoutSubMenu {
  name: string;
  items: MainMenuLayoutSubMenuItem[];
}

export interface ProductInfo {
  name: string;
  version: string;
  branding_url: string;
  configuration: {
    useDataTime: boolean;
    [key: string]: any;
  }
  mainMenuLayout?: MainMenuLayoutSubMenu[];
}

/**
 * Get the Product
 *
 * Looks to see if the product name is the default value, if it is, it will pull the runtime configuration.
 *
 * @returns a {@link Promise} that contains either the cached {@link ProductInfo} or fresh data.
 */
export const getProduct = async (): Promise<{
  product: ProductInfo;
  error?: null | string;
}> => {
  if (product.name === 'Integrated Operations Environment') {
    return getRuntimeConfiguration()
      .then(() => {
        return fetch(`${getWorkbenchUrl()}/core/product`);
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        // Make an attempt to return the cached information to recover from a bad request.
        return {
          product,
          error: `${res.status}: Error occurred when getting product info!`,
        };
      })
      .then((productInfo) => {
        if (productInfo.error) {
          return productInfo;
        }
        product = productInfo;
        return { product: productInfo, error: null };
      })
      .catch((e) => {
        // Make an attempt to return the cached information to recover from a bad request.
        return { product, error: (e as Error).message };
      });
  }
  return Promise.resolve({ product, error: null });
};

export const getWorkbenchUrl = () => {
  const ioeWindow = window as Record<string, any>;
  if(ioeWindow.ioe && ioeWindow.ioe.coreUrl) {
    return ioeWindow.ioe.coreUrl;
  }
  return getVariable("WORKBENCH_URL");
};
