import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useExternalNotificationWiringIncoming } from '../generated/applications/useExternalNotificationWiring';
import { useExternalRequestWiring } from '../generated/applications/useExternalRequestWiring';
import { setConfiguration } from '../store/actions/WorkbenchActions';
import { Perspective } from '../types/Perspective';
import { User, userFound, UserNotFound } from '../generated/user/util';
import { View } from '../types/View';
import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';

export interface ViewHandle {
  view: string;
  server: string;
  window: Window | null;
}

const useExternally = (user: User | undefined) => {
  const [openedExternally, setOpenedExternally] = useState<ViewHandle[]>([]);
  const dispatch = useDispatch();
  const { last_messageReceived } = useExternalNotificationWiringIncoming();
  const { send_view } = useExternalRequestWiring();

  const resetPerspective = (user: User, disabled: ViewHandle[]) => {
    if (user) {
      // we want to disable the view we opened
      // externally, until it is closed
      return fetch(
        `${getWorkbenchUrl()}/core/perspectives/views/disable?userId=${user.userId
        }`,
        {
          method: 'POST',
          body: JSON.stringify(disabled.map((vh) => vh.view)),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then((result) => {
        if (result.ok) {
          result.json().then((newConfig) => {
            dispatch(
              setConfiguration({
                ...newConfig,
                activePerspective: newConfig.perspectives.find(
                  (p: Perspective) =>
                    p.perspectiveId === newConfig.activePerspectiveId
                ),
              })
            );
          });
        }
      });
    }
  };

  const restoreView = (view: string) => {
    if (user) {
      const newExternals = openedExternally.filter((v) => v.view !== view);
      setOpenedExternally(newExternals);
      resetPerspective(user, newExternals);
    }
  };

  useEffect(() => {
    if (last_messageReceived) {
      if (
        openedExternally.find((v) => v.view === last_messageReceived.view) &&
        last_messageReceived.closed
      ) {
        restoreView(last_messageReceived.view);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last_messageReceived]);

  const openViewExternally = (
    user: User | UserNotFound | undefined,
    view: View
  ) => {
    if (user && userFound(user)) {
      const foundUser: User = user as User;
      const target = window.open(
        `${view.server}?user=${foundUser.username}&userId=${foundUser.userId}&sessionId=${foundUser.sessionId}`,
        '_blank'
      );
      const newlyDisabled = {
        view: view.name,
        server: view.server,
        window: target,
      };
      setOpenedExternally([...openedExternally, newlyDisabled]);
      resetPerspective(foundUser, [...openedExternally, newlyDisabled]);
    }
  };

  const requestRestoreView = (view: string) => {
    // we will restore the view regardless but also
    // want to request that the external view be closed
    const viewHandle = openedExternally.find((vh) => vh.view === view);
    if (viewHandle) {
      if (viewHandle.window !== null) {
        send_view(
          { view: viewHandle.view },
          viewHandle.window,
          viewHandle.server
        );
        restoreView(viewHandle.view);
      }
    }
  };

  return {
    openViewExternally,
    openedExternally,
    requestRestoreView,
  };
};

export default useExternally;
