import { ClosureMessage } from '../ClosureMessage';
import { useEffect, useState } from 'react';
import { MessageRequest } from '../common/MessageRequest';

/**
 * Incoming external notification
 */
export const useExternalNotificationWiringIncoming = () => {
  const [last_messageReceived, setLast_messageReceived] =
    useState<ClosureMessage>();

  const isExpectedSignal = (
    signal: string,
    message: MessageRequest<unknown>
  ): message is MessageRequest<unknown> => {
    if (
      (message as MessageRequest<unknown>).signal !== undefined &&
      message.signal === signal
    ) {
      return true;
    }
    return false;
  };

  const handleMessage = (event: MessageEvent) => {
    // ignore self originating events
    if (event.origin !== window.origin) {
      const data =
        event.data;
      if(isExpectedSignal('notifyClosed', data)) {
        const message = data as MessageRequest<ClosureMessage>;
        setLast_messageReceived(message.data);
      }

    }
  }

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);


  return {
    last_messageReceived,
  }
}
