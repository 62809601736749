import { View } from '../../types/View';
import Application from './Application';
import ApplicationInFrame from './ApplicationInFrame';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: View) => {
  const { applicationId, baseView, server, name, module, renderInFrame } = props;

  let applicationComponent = (
    <div>
      <div>I'm a view that should show application: {name}</div>
    </div>
  );

  if (renderInFrame && module) {
    applicationComponent = (
      <ApplicationInFrame name={name} server={server} applicationId={applicationId} />
    );
  }

  if (!renderInFrame && module) {
    applicationComponent = (
      <Application name={name} baseView={baseView} module={module} applicationId={applicationId} />
    );
  }

  return applicationComponent;
};
