import {
  requestClosureMessage,
} from './ExternalRequest'
import { ClosureRequest } from '../ClosureRequest';

export const useExternalRequestWiring = () => {
  const send_view = (message: ClosureRequest, target: Window, targetOrigin: string) => {
    target.postMessage(requestClosureMessage(message), targetOrigin);
  }

  return {
    send_view,
  }
}
