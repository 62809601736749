import ExternalView from './ExternalView';
import './styles/ExternalManagement.css';

export interface ExternalManagementProperties {
  views: string[];
  restoreView: (name: string) => void;
}

const ExternalManagement = (props: ExternalManagementProperties) => {
  const { views, restoreView } = props;

  return (
    <div className="ioe-workbench--external-management">
      <div>The following views are opened externally</div>
      <div className="ioe-workbench--external-management-views">
        {views.map((v) => (
          <ExternalView
            restoreView={restoreView}
            name={v}
          />
        ))}
      </div>
    </div>
  );
};

export default ExternalManagement;
