import { MicronnectDataMessage } from '../messages/MicronnectDataMessage';
// import { Connection, DirectConnection } from './Connection';

export interface Channel<T> {
  id: string;
  getManager: () => Worker;
  send: (message: MicronnectDataMessage) => void;
}

export const Channel = <T>(id: string, manager: Worker): Channel<T> => {
  const channelManager = manager;

  return {
    id: id,
    getManager: () => channelManager,
    send: (message) => {
      channelManager.postMessage(message);
    },
  };
};
