import React, { PropsWithChildren } from 'react';
import styles from './GlobalStatusBarTime.module.css';

const GlobalStatusBarTime = ({ children }: PropsWithChildren) => (
  <section
    className={`${styles['wrapper']} oa-w-full oa-h-[83px] oa-pt-1 oa-pb-0.2 oa-flex oa-mx-auto oa-my-0 oa-justify-center oa-items-center`}
  >
    {children}
  </section>
);

export default GlobalStatusBarTime;
