import React, { useEffect, useState } from 'react';
import '../styles/style.css';

export interface ApplicationProperties {
  name: string;
  baseView: string;
  module: string;
  applicationId: string;
}

const Application = (props: ApplicationProperties) => {
  const { name, baseView, module, applicationId } = props;
  const [appInterface, setAppInterface] = useState<{
    load: (container: HTMLElement | null, applicationId: string) => void;
    unload: () => void;
  }>();

  const baseModuleName = baseView ? baseView : name;
  const moduleName = `${(
    baseModuleName.charAt(0).toLowerCase() + baseModuleName.slice(1)
  ).replaceAll(' ', '')}`;
  const divId = `${(name.charAt(0).toLowerCase() + name.slice(1)).replaceAll(' ', '')}`;

  useEffect(() => {
    import(/* webpackIgnore: true */ module).then((app) => {
      // react app
      setAppInterface({
        load: (window as Record<string, any>)[moduleName].load,
        unload: (window as Record<string, any>)[moduleName].unload,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = document.getElementById(`external-div-${divId}`);
  useEffect(() => {
    if (appInterface && container) {
      appInterface.load(container, applicationId);
    }
    return () => {
      if (appInterface) {
        appInterface.unload();
      }
    };
  }, [applicationId, appInterface, container]);

  return (
    <div key={module} style={{ height: '100%' }}>
      <div
        key={divId}
        style={
          !appInterface
            ? {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }
            : { height: '100%' }
        }
        id={`external-div-${divId}`}
      >
        {!appInterface ? <div>Waiting for Application...</div> : undefined}
      </div>
    </div>
  );
};

export default Application;
