import { useEffect, useState } from 'react';
import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';

interface Extension {
  extensionId: string;
  extensionModule: string;
  extensionServer: string;
}

const usePlatformExtension = (point: string, containerId: string) => {
  const [extensionServer, setExtensionServer] = useState<string>('');
  useEffect(() => {
    fetch(`${getWorkbenchUrl()}/core/extensions/platform/${point}`).then((res) => {
      if (res.ok) {
        res.json().then((extension: Extension) => {
          if (extension.extensionServer) {
            setExtensionServer(extension.extensionServer);
          } else {
            import(/* webpackIgnore: true */ extension.extensionModule).then((a) => {
              (window as Record<string, any>)[extension.extensionId].extend(
                document.getElementById(containerId)
              );
            });
          }
        });
      }
    });
  }, [point, containerId]);

  return { extensionServer };
};
export default usePlatformExtension;
