import { PerspectiveChange } from './PerspectiveChange';
import { MessageRequest } from './common/MessageRequest';
export const changePerspectiveMessage = (
  state: PerspectiveChange
): MessageRequest<PerspectiveChange> => {
  return {
    sender: 'IOE Workbench',
    signal: 'changePerspective',
    data: state,
  };
};
