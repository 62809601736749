import { WorkbenchRoot } from "src/types/WorkbenchRoot";
import { User, getUser } from "../../generated/user/util";
import { shallowEqual, useSelector } from "react-redux";

export interface ApplicationProperties {
  name: string;
  server: string;
  applicationId: string;
}

const ApplicationInFrame = (props: ApplicationProperties) => {
  const { name, server } = props;

  // getting failed application so error message can be displayed in frame
  const failedApplications = useSelector(
    (state: WorkbenchRoot) => state.failedApplications.failedApplications, shallowEqual
  );

  const user: User = getUser() as User;

  return (
    <>
      {/* If Failed application equals server tell user the application failed */}
      {Array.isArray(failedApplications) && failedApplications.includes(server.replace("/", "")) ? (
        <div className="oa-flex oa-items-center oa-justify-center oa-h-full">
          <p className="oa-text-[14px]">{name} failed to load. Refresh the page to try again. </p>
        </div>
      ) : (
        <iframe
          style={{ width: "100%", height: "100%", border: "none" }}
          title={name}
          src={`${server}?userId=${user.userId}&sessionId=${user.sessionId}&user=${user.username}`}
        />
      )}
    </>
  );
};

export default ApplicationInFrame;
