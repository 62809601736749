import React, { PropsWithChildren } from 'react';

export interface TabProperties extends React.ComponentProps<'div'> {
  theme?: 'default' | 'astro';
  selected?: string;
  panel_id: string;
  styling?: object;
}

const base = 'oa-w-full oa-h-full oa-bg-tertiary-dark-500';

const TAB_PANEL_THEME = {
  default: `${base} oa-border-t oa-border-gray`,
  astro: `${base}`,
};

const TabPanel = (props: TabProperties & PropsWithChildren<unknown>) => {
  const { children, panel_id, selected, theme = 'default', styling } = props;

  const controlled = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as any, {
        selected,
      });
    }
    return null;
  });

  let classes = TAB_PANEL_THEME[theme];
  if (selected !== panel_id) {
    classes += ' oa-hidden';
  }

  return <div style={styling} className={classes}>{controlled}</div>;
};

export default TabPanel;
