import React, { useState } from 'react';
import { setUser } from '../user/util'; 
import { getWorkbenchUrl } from '../configuration/ioeConfiguration';

const VALID_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export interface DefaultAuthenticationProperties {
  setAuthenticated: (value: boolean) => void;
  setProceedWithAuthentication: (value: boolean) => void;
  onUserSet?: () => void;
}

const DefaultAuthentication = (props: DefaultAuthenticationProperties) => {
  const { onUserSet, setAuthenticated, setProceedWithAuthentication } = props;
  const [email, setEmail] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);

  const isValidEmail = (email: string) => {
    return VALID_EMAIL.test(email);
  }

  const handleSubmit = (email: string) => {
    if (isValidEmail(email)) {
      fetch(`${getWorkbenchUrl()}/core/users`, {
        body: JSON.stringify({
          username: email,
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.ok) {
          res.json().then((user) => {
            setUser(user);
            setAuthenticated(true);
            setProceedWithAuthentication(false);
            if(onUserSet) {
              onUserSet();
            }
          });
        }
      });
    } else {
      setEmailValidity(email)
    }
  };

  const setEmailValidity = (value: string) => {
    const valid = isValidEmail(value);
    if (!valid) {
      setErrorText(' - Invalid Email.');
      setDisabled(true);
    } else {
      setErrorText('');
      setDisabled(false);
    }
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailValidity(value);
  };

  return (
    <div className="ioe-authentication-form">
      <div className="ioe-participant-label">IOE Participant Login</div>
      <div className="ioe-authentication-inner-form">
        <div className="ioe-authentication-label-area">
          <label
            className="ioe-authentication-username-label"
            htmlFor="username"
          >
            Enter your email
          </label>
          {errorText && (
            <div className="ioe-authentication-label-error">{errorText}</div>
          )}
        </div>
        <input
          className="ioe-authentication-username-input"
          id="username"
          value={email}
          onChange={(event) => handleEmailChange(event.target.value)}
        />
        <div className="ioe-authentication-button-bar">
          <button
            className="lg outline"
            disabled={disabled}
            type="button"
            onClick={() => handleSubmit(email)}
          >
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};
export default DefaultAuthentication;
