import React, { PropsWithChildren } from 'react';
import styles from './Tabs.module.css';

export interface TabsProperties extends React.ComponentProps<'div'> {
  theme?: 'default' | 'astro';
  selected?: boolean;
  setSelected?: (id: string) => void;
}

const base =
  'oa-relative oa-px-1.4 oa-py-1/2 oa-flex oa-items-center oa-min-w-0 oa-w-full oa-relative oa-flex-[1] oa-max-h-[40px] oa-cursor-pointer oa-max-w-fit oa-rounded-none oa-leading-[inherit] enabled:hover:oa-text-secondary-light-500 oa-font-medium';

// Tab Theme Fns must be an object with a function that returns a string.
type TabThemeFns = Record<string, (active: boolean) => string>;

// WHEN CHANGING THINGS make sure to test you didn't break the other theme!
/**
 * The Tab theme functions
 *
 * Overriding isn't as nice with utility classes. So these functions take
 * in a boolean argument indicating activity. This can be used to remove
 * classes from these base styles when a class used in the active base
 * styles would conflict.
 */
const TABS_THEME_FNS: TabThemeFns = {
  default(active: boolean) {
    return `${base} ${styles['tab']}${
      active
        ? ' enabled:hover:oa-bg-tertiary-dark-500'
        : ' enabled:hover:oa-bg-transparent'
    } oa-text-secondary oa-rounded-t-xl oa-text-[14px]`;
  },
  astro(active: boolean) {
    return `${base} oa-border-b-2${
      active ? '' : ' oa-border-b-transparent'
    } oa-text-secondary enabled:hover:oa-bg-transparent oa-leading-none oa-pb-0.4 oa-text-[16px]`;
  },
};

/**
 * The Tabs Active theme
 *
 * These classes get added when active to the tab. Therefore they are not
 * implemented the same as the base classes, where they need classes added
 * or removed, because they'll all added/removed depending on active-ness.
 */
const TABS_ACTIVE_THEME = {
  default: `enabled:hover:oa-text-white enabled:hover:oa-bg-tertiary-dark-500 ${styles['active']} oa-bg-tertiary-dark-500 oa-border oa-border-gray oa-border-b-0 oa-text-white`,
  astro: `enabled:hover:oa-text-white oa-text-white oa-border-b-2 oa-border-b-secondary`,
};

const Tabs = (
  props: TabsProperties & PropsWithChildren<Record<string, unknown>>
) => {
  const {
    children,
    selected,
    setSelected,
    className,
    theme = 'default',
  } = props;

  let wrapperClasses =
    'oa-flex oa-flex-wrap oa-m-0 oa-relative oa-items-end oa-h-5 oa-pt-[5px] oa-pb-0 -oa-mb-[1px]';
  if (className) {
    wrapperClasses += className;
  }

  return (
    <section className={wrapperClasses}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if ((child.props as any).tab_id !== undefined) {
            const active = (child.props as any).tab_id === selected;
            // selection support
            let classes = TABS_THEME_FNS[theme](active) ?? '';
            if (active) {
              classes += ` ${TABS_ACTIVE_THEME[theme]}`;
            }
            return (
              <button
                className={classes}
                onClick={() => {
                  if (setSelected) {
                    setSelected((child.props as any).tab_id);
                  }
                }}
              >
                {child.props.children}
              </button>
            );
          }
          return React.cloneElement(child as any, { hidden: !selected });
        }
        return null;
      })}
    </section>
  );
};

export default Tabs;
