import { FloatingMenuItem } from '$design-system/packages/react-design-system/src/components/menu/FloatingMenu';
import '$design-system/packages/web-components/modal/Modal';
import '$design-system/packages/web-components/modal/types/Modal.ds';
import React, { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../../context/Product';
import usePlatformExtension from '../../extensions/usePlatformExtension';
import { User, getUser, removeUser, userFound } from '../../generated/user/util';
import { ViewHandle } from '../../hooks/useExternally';
import useTool from '../../hooks/useTool';
import { executeContextTool } from '../../tools/Execution';
import { Tool } from '../../types/Tool';
import { WorkbenchConfiguration } from '../../types/WorkbenchConfiguration';
import GlobalStatusBar from '../global/GlobalStatusBar/GlobalStatusBar';
import ApplicationInFrame from '../views/ApplicationInFrame';
import './styles/WorkbenchBar.css';
import menuHamburger from '../../assets/icons/hamburger-green.svg';
import { MenuContext } from '../../context/Menu';
import AboutModal from './AboutModal';
import { aboutModalItem, logoutItem, perspectivesMenuItem } from './MenuItems';
import ConfiguredMenu from './WorkbenchConfiguredMenu';

export interface WorkbenchBarProperties {
  restoreView: (view: string) => void;
  externalViews: ViewHandle[];
  configuration: WorkbenchConfiguration;
  setShowLoadingScreen: (data: boolean) => void;
  setLoadingScreenEnabled: (data: boolean) => void;
  setPerspective: (data: string) => void;
}

const WorkbenchTopBar = (props: WorkbenchBarProperties) => {
  const { configuration, setShowLoadingScreen, setLoadingScreenEnabled, setPerspective } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, _setItems] = useState<FloatingMenuItem[]>([]);
  const [brandingLogo, setBrandingLogo] = useState<string>();
  const product = useContext(ProductContext);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [activeEventId, setActiveEventId] = useState<string>('');

  usePlatformExtension('topBar::left', 'left-point');
  const { extensionServer: centerAppServer } = usePlatformExtension(
    'topBar::center',
    'center-point'
  );
  usePlatformExtension('topBar::right', 'right-point');
  usePlatformExtension('data::attributions', 'attributions-point');
  const { extensionServer: logoOverride } = usePlatformExtension(
    'branding::menu-logo',
    'logo-point'
  );
  let centerApp = undefined;
  if (centerAppServer !== '') {
    // use iframe here
    centerApp = (
      <ApplicationInFrame
        name="top-bar-center"
        applicationId="workbench-frontend"
        server={centerAppServer}
      />
    );
  }

  const user = getUser();
  let username = 'Unknown User';
  let foundUser: User | undefined = undefined;
  if (userFound(user)) {
    foundUser = user as User;
    username = foundUser.username;
  }

  const setActivePerspective = useTool({
    user: foundUser,
    tool: 'Set Perspective',
    configuration: configuration,
  });

  setActivePerspective?.options?.sort((a, b) => {
    return a.label.localeCompare(b.label);
  })

  const setItems = (items: FloatingMenuItem[]) => {
    _setItems(items);
  };
  // initialize items
  useEffect(() => {
    // NOTE: the handler and submenu functions are kept up to date
    //       with the items available by residing in the useEffect
    const eventHandler = (eventId: string, e: React.MouseEvent, value: string) => {
      // submenu items should not have mouseover events
      // this configuration does not support nested submenus
      if (e.type === 'mouseover') {
        if (eventId !== activeEventId) {
          setActiveEventId(eventId);
          handleShowSubmenu(eventId, true);
          return;
        }
      }

      setActiveEventId('');

      switch (eventId) {
        case 'openAboutModal':
          setIsModalOpen(true);
          break;

        case 'setPerspective':
          let newPerspective = "";
          let perspectiveId = "";
          // getting new perspective to create a state change with the perspective
          setActivePerspective.options.forEach((perspective) => {
            if (value === perspective.value || value === perspective.label) {
              newPerspective = perspective.label;
              perspectiveId = perspective.value;
            }
          });
          if (newPerspective && perspectiveId) {
            setShowLoadingScreen(true);
            setLoadingScreenEnabled(false);
            setPerspective(newPerspective);
            executeContextTool(foundUser, setActivePerspective as Tool, [perspectiveId]);
          }
          break;

        case 'logout':
          removeUser();
          window.location.reload();
          break;
      }
    };

    const baseMenu: FloatingMenuItem[] = [
      aboutModalItem(eventHandler, product?.name),
      logoutItem(eventHandler),
    ]

    let currentMenuState: FloatingMenuItem[]
    
    if (product?.mainMenuLayout) {
      currentMenuState = [...ConfiguredMenu({ mainMenuLayout: product.mainMenuLayout, eventHandler }), ...baseMenu]
    } else {
      currentMenuState = [perspectivesMenuItem({setActivePerspective, eventHandler}), ...baseMenu]
    }

    const handleShowSubmenu = (eventId: string, show: boolean) => {
      setActiveEventId('');
      const updated = currentMenuState.map((i) => {
        if (i.eventId === eventId) {
          i.showSubItems = show;
        } else {
          i.showSubItems = false;
        }
        return i;
      });
      setItems(updated);
    };

    setItems(currentMenuState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActivePerspective, product, mainMenuOpen]);

  /**
   * Handles setting the logo img src for the GlobalStatusBar.
   * Menu logo will default to menuHamburger.
   * If a 'product.branding_url' is defined and contains a ".png" image
   * in the public/icons directory that will be as the logo.
   * If a 'branding::menu-logo' extension point is defined, that
   * will override and take precedence over any other defined img src.
   */
  useEffect(() => {
    let brandingSrc = menuHamburger;
    if (product.branding_url) {
      brandingSrc = `${product.branding_url}/icons/logo.png`;
    }
    if (logoOverride) {
      brandingSrc = logoOverride;
    }
    setBrandingLogo(brandingSrc);
  }, [logoOverride, product]);

  /**
   * Provides a fallback to the menuHamburger if another img src fails
   */
  const onLogoImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = menuHamburger;
  };

  return (
    <div id="ioe-top-bar" className="ioe-workbench--top-bar oa-bg-tertiary-dark-500">
      <MenuContext.Provider value={{ mainMenuOpen, setMainMenuOpen }}>
        <GlobalStatusBar
          Logo={<img alt="" src={brandingLogo} onError={onLogoImgError} />}
          label={product?.name}
          username={username}
          TimeApp={centerApp || 'center-point'}
          menuItems={items}
        />
        <AboutModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} product={product} />
      </MenuContext.Provider>
    </div>
  );
};

export default WorkbenchTopBar;
