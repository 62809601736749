import { Channel } from '../channel/Channel';
import { MicronnectInitMessage } from '../messages/MicronnectInitMessage';
import { RequestOptions, RequestOptionsIn } from '../messages/RequestOptions';
import { ChannelResult } from '../results/ChannelResult';
import { BusConfiguration } from './BusConfiguration';
import { StreamConfiguration } from '../streams/StreamConfiguration';
import { StreamConsumer } from '../streams/StreamConsumer';

export interface CommunicationBus {
  id: string;
  addChannel: <T>(
    id: string,
    options?: RequestOptions<T>
  ) => Promise<ChannelResult<T>>;
  updateChannel: <T>(
    id: string,
    options?: RequestOptions<T>
  ) => ChannelResult<T>;
  removeChannelArtifacts: (id: string) => void;
}

const consumers: StreamConsumer<any>[] = [];
export const CommunicationBus = (
  busId: string,
  configuration: BusConfiguration,
  workbenchUrl: string
): CommunicationBus => {
  const channels: Map<string, Channel<unknown>> = new Map();
  // create a consumer thread for each stream in the configuration
  configuration.streams.forEach((stream) =>
    createConsumer(stream, workbenchUrl)
  );
  return {
    id: busId,
    addChannel: async <T>(
      id: string,
      options?: RequestOptions<T>
    ): Promise<ChannelResult<T>> => {
      const internalId = id + '-micronnect';
      if (channels.get(id) !== undefined) {
        return {
          channel: channels.get(id),
          status: 'INFO',
          message: 'Reusing existing channel',
        };
      }
      const manager = await fetch(
        `${workbenchUrl}/core/workers/support/ChannelManager/${internalId}`
      ).then((res) => {
        return res.text().then((workerContent) => {
          return new Worker(URL.createObjectURL(new Blob([workerContent])), {
            name: id,
          });
        });
      });
      // we create two channels, an internal one which communicates
      // in background tasks and the main one that communicates back
      // to the client
      const channel = Channel(id, manager);
      const internal = Channel(internalId, manager);
      // the channel worker communicates back to the
      // client after requested adjustments
      manager.postMessage({
        type: 'initialization',
        clientChannelId: id,
        internalChannelId: internalId,
        options: options ? marshallOptions(options) : undefined,
      } as MicronnectInitMessage);
      // if there is a stream consumer with a matching id
      // register with it
      const consumer = consumers.find(
        (c) => c.configuration.id === options?.source
      );
      if (consumer !== undefined) {
        consumer.registerChannel(internal, options?.streamName);
      }
      channels.set(channel.id, channel);
      channels.set(internal.id, internal);
      return {
        status: 'OK',
        channel: channel,
      };
    },
    updateChannel: <T>(
      id: string,
      options?: RequestOptions<T>
    ): ChannelResult<T> => {
      const channel = channels.get(id);
      if (!channel || !options) {
        return {
          status: 'FAIL',
        };
      }
      channel.getManager().postMessage({
        type: 'update',
        clientChannelId: id,
        options: marshallOptions(options),
      } as MicronnectInitMessage);
      return {
        status: 'OK',
      };
    },
    removeChannelArtifacts: (id: string) => {
      const channel = channels.get(id);
      channel?.getManager().terminate();
      channels.delete(id);
      channels.delete(id + '-micronnect');
    },
  };
};

const createConsumer = (stream: StreamConfiguration, workbenchUrl: string) => {
  StreamConsumer(stream, workbenchUrl).then((consumer) => {
    consumers.push(consumer);
  });
};

const stringifyFunction = function (obj: Function) {
  return JSON.stringify(obj, function (key, value) {
    if (false) {
      console.log(key);
    }
    return typeof value === 'function' ? value.toString() : value;
  });
};

const marshallObject = (object: Record<string, any>): Record<string, any> => {
  Object.keys(object).forEach((key) => {
    if (isFunction(object[key])) {
      object[key] = stringifyFunction(object[key]);
    } else if (typeof object[key] === 'object') {
      object[key] = marshallObject(object[key]);
    }
  });
  return object;
};

const marshallFilterParams = (
  filterParams: Record<string, any> | undefined
): Record<string, any> | undefined => {
  if (filterParams) {
    // look for any functions in the params
    return marshallObject(filterParams);
  }
  return undefined;
};

const marshallOptions = <T>(options: RequestOptions<T>): RequestOptionsIn => {
  return {
    source: options.source,
    dataId: options.dataId,
    failOnDestructure: options.failOnDestructure,
    filterParams: marshallFilterParams(options.filterParams),
    filter:
      options.filter && isFunction(options.filter)
        ? stringifyFunction(options.filter as Function)
        : options.filter
        ? (options.filter as string)
        : '',
    map:
      options.map && isFunction(options.map)
        ? stringifyFunction(options.map as Function)
        : options.map
        ? (options.map as string)
        : '',
    requestStructure: options.requestStructure,
    batch: options.batch,
  };
};

const isFunction = function (obj: any) {
  if (obj === undefined) {
    return false;
  }
  return !!(obj && obj.constructor && obj.call && obj.apply);
};
