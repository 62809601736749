import { BiWindowOpen } from 'react-icons/bi';

export interface ExternalViewProperties {
  name: string;
  restoreView: (name: string) => void;
}

const ExternalView = (props: ExternalViewProperties) => {
  const { name, restoreView } = props;
  return (
    <div className="ioe-workbench--external-management-external-view">
      <BiWindowOpen className="ioe-workbench--external-management-external-view-icon" />
      <div>{name}</div>
      <button
        onClick={() => restoreView(name)}
        className="ioe-workbench--external-management-external-view-restore"
      >
        Restore
      </button>
    </div>
  );
};

export default ExternalView;
