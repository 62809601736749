import { getUser, User, userFound } from '../../generated/user/util';
import useTool from '../../hooks/useTool';
import { executeContextTool } from '../../tools/Execution';
import { Container } from '../../types/Container';
import { Tool } from '../../types/Tool';
import { WorkbenchConfiguration } from '../../types/WorkbenchConfiguration';
import '../styles/ViewSelector.css';

export interface ViewSelectorProperties {
  show: boolean;
  setShow: (show: boolean) => void;
  containerContext: Container | undefined;
  configuration: WorkbenchConfiguration;
}

const ViewSelector = (props: ViewSelectorProperties) => {
  const { show, setShow, containerContext, configuration } = props;
  const user = getUser();
  let foundUser: User | undefined = undefined;
  if (userFound(user)) {
    foundUser = user as User;
  }
  const tool = useTool({
    user: foundUser,
    tool: 'Add View',
    configuration: configuration,
  });

  const handleViewSelected = (value: string) => {
    if (containerContext) {
      executeContextTool(foundUser, tool as Tool, [
        value,
        containerContext.containerId,
      ]);
      setShow(false);
    }
  };

  return show ? (
    <div className="modal">
      <div className="modal-content">
        <span
          onClick={() => setShow(false)}
          className="close"
        >
          &times;
        </span>
        <div>{tool ? (tool as Tool).name : ''}</div>
        {tool &&
          (tool as Tool).options.map((o) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <p>{o.label}</p>
              <button onClick={() => handleViewSelected(o.value)}>Add</button>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default ViewSelector;
