import { Action } from '../../types/Action';
import { Workbench } from '../../types/Workbench';
import { WorkbenchConfiguration } from '../../types/WorkbenchConfiguration';
import { SETCONFIGURATION } from '../actions/WorkbenchActions';

export const WorkbenchReducer = (state: any, action: Action<WorkbenchConfiguration>): Workbench => {
  switch (action.type) {
    case SETCONFIGURATION:
      return {
        ...state,
        WorkbenchConfiguration: action.payload as WorkbenchConfiguration,
      };
    default:
      // this allows an empty setting, while still making
      // typescript happy
      return state || ({} as any);
  }
};
