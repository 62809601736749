import { Action } from '../../types/Action';
import { SETFAILEDAPPLICATIONS } from '../actions/FailedApplicationsActions';

export const FailedApplicationsReducer = (state: any, action: Action<string[]>): string[] => {
  switch (action.type) {
    case SETFAILEDAPPLICATIONS:
      return {
        ...state,
        failedApplications: action.payload as string[],
      };
    default:
      // this allows an empty setting, while still making
      // typescript happy
      return state || ([] as any);
  }
};
