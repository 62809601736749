import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connectToModelStream } from '../communications/CoreMessages';
import { usePerspectiveManagementWiring } from '../generated/signals/usePerspectiveManagementWiring';
import { setConfiguration } from '../store/actions/WorkbenchActions';
import { Perspective } from '../types/Perspective';
import { WorkbenchConfiguration } from '../types/WorkbenchConfiguration';

const useConfigurationUpdates = (sessionId: string | undefined, loadingScreen) => {
  const dispatch = useDispatch();
  const { send_changePerspective } = usePerspectiveManagementWiring();
  const [activePerspectiveId, setActivePerspectiveId] = useState<string | null>(null);

  useEffect(() => {
    send_changePerspective({ perspectiveId: activePerspectiveId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePerspectiveId]);

  useEffect(() => {
    if (sessionId) {
      const CoreMessageBus = connectToModelStream(sessionId);
      if (CoreMessageBus && !loadingScreen) {
        /**
         * micronnect is running the retrieval, and filtering then posting to a new
         * channel ConfigurationUpdates we collect those filtered results
         */
        CoreMessageBus.addChannel('ConfigurationUpdate', {
          source: CoreMessageBus.id,
          streamName: 'ConfigurationUpdate',
          /**
           * Filter the stream messages
           *
           * @param datum - the WorkbenchConfiguration coming from the signal
           * @returns {boolean} for the filter condition
           */
          filter: (datum: WorkbenchConfiguration) => {
            if (datum.configurationId !== undefined) {
              return true;
            }
            return false;
          },
        });

        // Create a new channel listener.
        const channel = new BroadcastChannel('ConfigurationUpdate');
        channel.addEventListener('message', (event) => {
          if (event.data.content && event.data.content.configurationId) {
            // TODO need to revisit this, when in local development all browser
            // tabs will receive duplicated messages, even though fitlered out
            // server level by user, probably something that should be filtered
            // out at the micronnect level as well
            if (event.data.content.sessionId === sessionId) {
              const config = event.data.content as WorkbenchConfiguration;
              if (activePerspectiveId !== config.activePerspectiveId) {
                setActivePerspectiveId(config.activePerspectiveId);
              }
              dispatch(
                setConfiguration({
                  ...config,
                  activePerspective: config.perspectives.find(
                    (p: Perspective) => p.perspectiveId === config.activePerspectiveId
                  ),
                })
              );
            }
          }
        });
      }
    }
  }, [dispatch, sessionId, loadingScreen, activePerspectiveId]);
};

export default useConfigurationUpdates;
