
import { PerspectiveChange } from '../PerspectiveChange';

import { changePerspectiveMessage,  } from '../PerspectiveManagement';
import { PerspectiveManagementChannel } from './PerspectiveManagementChannel';
import { CommunicationSystem } from '../communicationSystem/CommunicationSystem';
import { CommunicationSystemPost } from '../communicationSystem/CommunicationSystemPost';
import { Direction } from '../common/Direction';

let channels: Map<string, BroadcastChannel | Window> = new Map();

export const usePerspectiveManagementWiring = () => {

  const _PerspectiveManagementChannel: PerspectiveManagementChannel = {
    id: 'PerspectiveManagement'
  }

  const getChannel = (
    id: string,
  ): BroadcastChannel | Window | CommunicationSystemPost => {
      const channel = channels.get(id);
      if (!channel) {
        // some browsers do not support BroadcastAPI
        // in those cases we use the window object
        let newChannel: BroadcastChannel | Window | undefined = undefined;
        if (window.BroadcastChannel) {
          // Broadcast API indicates that if a channel is already
          // created it will be used, therefore we do not require
          // managing an instance with this hooks state
          newChannel = new BroadcastChannel(id);
        } else {
          newChannel = window;
        }
        channels.set(id, newChannel);
        return newChannel;
      }
      return channel;
  };

  const send_changePerspective = (message: PerspectiveChange) => {
    getChannel(_PerspectiveManagementChannel.id).postMessage(changePerspectiveMessage(message));
        // send via backend communication system
    if((window as Record<string, any>).ioe?.initialized) {
      CommunicationSystem(Direction.OUT).postMessage(changePerspectiveMessage(message));
    }

  }




  return {
      send_changePerspective,

  }
}
