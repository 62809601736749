import { VscClose } from 'react-icons/vsc';
import { IoOpenOutline } from 'react-icons/io5';
import { View } from '../../types/View';

const disableApplicationPopOutForThisPI = true;

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  props: View & {
    closeEnabled: boolean;
    handleClose: (view: View) => void;
    handleOpenExternal: (view: View) => void;
  }
) => {
  const { name } = props;
  const { closeEnabled, handleClose, handleOpenExternal, ...rest } = props;

  return (
    <>
      <div className="oa-mx-1/4 oa-my-1/2 oa-block oa-text-ellipsis oa-overflow-hidden oa-flex-1 oa-whitespace-nowrap">
        {name}
      </div>
      <div className="oa-flex oa-items-center oa-justify-center oa-rounded-sm">
        {closeEnabled ? (
          <button
            onClick={() => handleClose(rest)}
            title="Close View"
            className="deneir-wecop-ui--view-tab-button"
          >
            <VscClose className="deneir-wecop-ui--view-tab-button-icon-warning light" />
          </button>
        ) : undefined}
        {!disableApplicationPopOutForThisPI && (
          <button
            onClick={() => handleOpenExternal(rest)}
            title="Open Externally"
            className="deneir-wecop-ui--view-tab-button"
          >
            <IoOpenOutline className="deneir-wecop-ui--view-tab-button-icon-action light" />
          </button>
        )}
      </div>
    </>
  );
};
