import { FloatingMenuItem } from '$design-system/packages/react-design-system/src/components/menu/FloatingMenu';
import { MenuEventHandler, addSubmenuArrow } from './MenuItems';
import { MainMenuLayoutSubMenu } from 'src/generated/configuration/ioeConfiguration';

interface ConfiguredMenuProps {
  mainMenuLayout: MainMenuLayoutSubMenu[],
  eventHandler: MenuEventHandler
}

// This creates an alternative main menu layout if available in perspectives.yml
const ConfiguredMenu = (props: ConfiguredMenuProps): FloatingMenuItem[] => {
  const { mainMenuLayout, eventHandler } = props;

  const parseSubItem = (subItem) => {
    if (subItem.url) {
      return {
        label: subItem.name,
        eventId: subItem.name,
        onClick: () => window.open(subItem.url, '_blank').focus(),
      }
    } else {
      return {
        eventId: 'setPerspective',
        label: subItem.name,
        value: subItem.name,
        onClick: eventHandler,
      }
    }
  }

  return mainMenuLayout.map(subMenu => {
    return {
      eventId: `show${subMenu.name.split(' ').join()}Menu`,
      label: addSubmenuArrow(subMenu.name),
      onMouseOver: eventHandler,
      showSubItems: false,
      subItems: subMenu.items.map(subItem => {
        return parseSubItem(subItem);
      })
    }
  })
}

export default ConfiguredMenu;