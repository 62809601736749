import React, { useEffect, useState } from 'react';
import ReactDOMRoot from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import Workbench from './Workbench';
import Authentication from './generated/authentication/Authentication';
import {
  getProduct,
  getVariable,
  getWorkbenchUrl,
} from './generated/configuration/ioeConfiguration';
import './index.css';
import { dataStore } from './store/Store';

import { ProductContext } from './context/Product';
import { changeFavicon } from './favicon';
import { removeUser } from './generated/user/util';
import MessageModal from './components/utility/MessageModal';

const theWindow = window as Record<string, any>;
theWindow.react = React;
theWindow.React = React;
theWindow['react-dom'] = ReactDOMRoot;
theWindow.ReactDOM = ReactDOMRoot;

document.title = 'Loading...';
const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  const RootApplication = () => {
    const [ready, setReady] = useState<boolean>(false);
    const [product, setProduct] = useState(null);
    const [isSessionTimeoutModalOpen, setIsSessionTimeoutModalOpen] = useState(false);
    /**
     * Block further request until we have attempted to
     * load the configuration
     */
    useEffect(() => {
      getProduct().then(({ product }) => {
        setProduct(product);
        const ioeWindow = window as Record<string, any>;
        if (!ioeWindow.ioe) {
          ioeWindow.ioe = {};
        }
        // Support CESIUM
        // dynamically load css based on runtime workbench url
        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('link');
        style.href = `${getWorkbenchUrl()}/libraries/cesium/Widgets/widgets.css`;
        style.type = 'text/css';
        style.rel = 'stylesheet';
        head.append(style);

        const overrideStyleUrl = getVariable('OVERRIDE_IOE_STYLE_URL');
        if (overrideStyleUrl) {
          const overrideStyle = document.createElement('link');
          overrideStyle.href = overrideStyleUrl;
          overrideStyle.type = 'text/css';
          overrideStyle.rel = 'stylesheet';
          head.append(overrideStyle);
        }
        
        (window as any).CESIUM_BASE_URL = getWorkbenchUrl() + '/libraries/cesium';
        // setup variables for hosted application use
        ioeWindow.ioe.coreUrl = getWorkbenchUrl();
        ioeWindow.ioe.DYNAMIC_IMAGERY_URL = getVariable('DYNAMIC_IMAGERY_URL');
        ioeWindow.ioe.IOE_TABLE_URL = getVariable('IOE_TABLE_URL');

        // setup the Favicon
        changeFavicon(`${product.branding_url}/icons/favicon.ico`);
        document.title = product.name;
        setReady(true);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Listen for SSE failures, which are published on EventSource.Error channel
    const closeChannel = new BroadcastChannel("EventSource.Error");
    closeChannel.addEventListener("message", (e) => { 
      if (e.data.streamId==="core-messages" && e.data.error) {
        // We had an error on our primary SSE stream, indicating that workbench-core will no
        // longer respond to requests from this session.  We expect this to primarily occur 
        // due to session inactivity (e.g. computer went to sleep, etc...).  Open a modal
        // notifying the user when this happens with an option to login to a new session.
        
        // NOTE: for now, setting the modal to false to prevent showing the "Session Timeout"
        // popup.  This error event was happening much more frequently than anticipated and the
        // modal was creating a bad experience. I expect this will be changed to be set to true
        // here sooner than later, but setting to false is a quick fix for now.
        setIsSessionTimeoutModalOpen(false);
      }
    });

    const login = () => {
      removeUser();
      window.location.reload();
    }

    return ready ? (
      <Provider store={dataStore}>
        <Authentication>
          <ProductContext.Provider value={product}>
            <Workbench />
            <MessageModal 
              isModalOpen={isSessionTimeoutModalOpen} 
              title={"Session Timeout"} 
              message={"Your session has timed out.  Click \"Login\" to start a new session."}
              buttonText={"Login"}
              buttonCallback={login}></MessageModal>
          </ProductContext.Provider>
        </Authentication>
      </Provider>
    ) : null;
  };
  root.render(<RootApplication />);
}
