import { BusConfiguration } from './bus/BusConfiguration';
import { CommunicationBus } from './bus/CommunicationBus';
import { CommunicationBusResult } from './results/CommunicationBusResult';

const buses: CommunicationBus[] = [];
export const createBus = (
  id: string,
  configuration: BusConfiguration,
  workbenchUrl: string
): CommunicationBusResult => {
  const existingBus = buses.find(b => b.id === id);
  if (existingBus !== undefined) {
    return { status: 'EXISTING_OK', bus: existingBus };
  }
  const bus = CommunicationBus(id, configuration, workbenchUrl);
  buses.push(bus);
  return { status: 'OK', bus: bus };
};

(window as Record<string, any>).createBus = createBus;
