import { FC, useEffect, useState } from 'react';
import './LoadingSplash.scss';
import deneirLoader from '../../assets/img/deneir-loader.gif';
import { RingLoader } from 'react-spinners';

interface LoadingSplashProps {
  isLoading: boolean;
  enabled: boolean;
  animationTime?: number;
  children: any
}

const LoadingSplash: FC<LoadingSplashProps> = ({
  isLoading,
  enabled,
  animationTime = 500,
  children,
}) => {
  const [willUnload, setWillUnload] = useState(false);
  const [leftClicks, setLeftClicks] = useState<number[]>([]);
  const [doneAnimating, setDoneAnimating] = useState(false);

  useEffect(() => {
    if (!isLoading && !willUnload) {
      setWillUnload(true);
      setTimeout(() => {
        setDoneAnimating(true);
      }, animationTime + 150);
    }
    if (isLoading) {
      setWillUnload(false);
      setDoneAnimating(false);
    }
  }, [isLoading, willUnload, animationTime]);

  const handleClick = () => {
    setLeftClicks((prev) => [...prev, 1]);
  };

  const loadingAnimation = isLoading
    ? {}
    : {
      transition: `opacity ${animationTime}ms 0ms`,
      opacity: 0,
    };

  if (doneAnimating) {
    return null;
  }

  const alternateLoader = leftClicks.length > 5;

  return (
    <div style={loadingAnimation} className={'oa-panel-loading'}>
      <div className="oa-right-loading">
        {enabled ? (
          <>
            <img
              style={{ width: '250px', height: 'auto' }}
              className={!alternateLoader ? 'hidden' : undefined}
              id="oa-right-alternate-img"
              src={deneirLoader}
              alt="" //empty string here & below because the 'alt' string was rendering with slower load speeds
            />
            <div className="flex" data-testid="ring-loader-test" onClick={() => handleClick()}>
              <RingLoader
                loading={!alternateLoader}
                size="75px"
                color="white"
              />
            </div>
            <br />
            {children}
          </>
        ) : null}
      </div>
    </div>
  )
};

export default LoadingSplash;
