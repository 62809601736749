import React from "react";
import { FloatingMenuItem } from "$design-system/packages/react-design-system/src/components/menu/FloatingMenu"
import { Tool } from "src/types/Tool";

export type MenuEventHandler = (eventId: string, e: React.MouseEvent, value: string) => void;

export const addSubmenuArrow = (label: string) => {
  return (
    <div className="oa-flex oa-justify-between">
      <div className='oa-mr-[8px]'>{label}</div>
      <div>{' \u25B8'}</div>
    </div>
  )
}

export const aboutModalItem = (eventHandler: MenuEventHandler, productName: string): FloatingMenuItem => {
  return {
    eventId: 'openAboutModal',
    label: `About ${productName}`,
    onClick: eventHandler,
    onMouseOver: eventHandler,
  }
}

export const logoutItem = (eventHandler: MenuEventHandler): FloatingMenuItem => {
  return {
    eventId: 'logout',
    label: 'Sign Out',
    onClick: eventHandler,
    onMouseOver: eventHandler,
  }
}

export const perspectivesMenuItem = ({
  setActivePerspective,
  eventHandler,
}: {
  setActivePerspective: Tool,
  eventHandler: MenuEventHandler,
}) => {
  const multiplePerspectives = setActivePerspective?.options?.length > 0
  const perspectiveLabel = multiplePerspectives ? addSubmenuArrow('Perspectives')
    : (<div className="oa-text-gray" style={{pointerEvents: "none"}}>Perspectives</div>)
  return {
    eventId: 'showPerspectiveMenu',
    label: perspectiveLabel,
    onMouseOver: eventHandler,
    disabled: !multiplePerspectives,
    showSubItems: false,
    subItems: setActivePerspective?.options.map((o) => {
      return {
        eventId: 'setPerspective',
        label: o.label,
        value: o.value,
        onClick: eventHandler,
      };
    }),
  }
}