import React, { ReactNode } from 'react';
import { FloatingMenuItem } from '$design-system/packages/react-design-system/src/components/menu/FloatingMenu';
import GlobalStatusBarAppInfo from '../GlobalStatusBarAppInfo/GlobalStatusBarAppInfo';
import GlobalStatusBarLogo from '../GlobalStatusBarLogo/GlobalStatusBarLogo';
import GlobalStatusBarTime from '../GlobalStatusBarTime/GlobalStatusBarTime';
import { getVariable } from '../../../generated/configuration/ioeConfiguration';
import './styles/playback.css';

export interface GlobalStatusBarProps {
  Logo: ReactNode | undefined;
  label: string;
  username: string;
  TimeApp: ReactNode | string;
  menuItems?: FloatingMenuItem[];
}

const GlobalStatusBar = ({
  Logo,
  label,
  username,
  TimeApp,
  menuItems = [],
}: GlobalStatusBarProps) => {
  const playbackMode: boolean = getVariable("STREAMING_MODE") && getVariable("STREAMING_MODE").toLowerCase() === "playback" ? true : false;
  return (
    <header className="oa-px-2.4 oa-grid oa-grid-cols-[25%_minmax(900px,_1fr)_25%] oa-grid-rows-1">
      <section className="oa-flex oa-gap-1.4 oa-ml-0">
        <GlobalStatusBarLogo menuItems={menuItems}>
          <div className="oa-pointer-events-none oa-w-[40px]" id="logo-point">
            {Logo}
          </div>
        </GlobalStatusBarLogo>
        <GlobalStatusBarAppInfo label={label} username={username} />
      </section>
      <div className="oa-w-full oa-h-full oa-flex oa-items-center oa-justify-center oa-bg-tertiary-dark-500">
        <section className={playbackMode ? "oa-relative oa-pb-[25px] oa-pl-[60px]" : "oa-hidden"}>
          <div className='playback-pill'>PLAYBACK</div>
        </section>
        <GlobalStatusBarTime>
          {typeof TimeApp === 'string' ? <div id={TimeApp as string} /> : TimeApp}
        </GlobalStatusBarTime>
        <section className={playbackMode ? "oa-relative oa-pb-[25px] oa-pr-[60px]" : "oa-hidden"}>
          <div className='playback-pill'>PLAYBACK</div>
        </section>
      </div>
    </header>
  );
};

export default GlobalStatusBar;
