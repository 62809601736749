import { createBus } from 'micronnect';
import { CommunicationBus } from 'micronnect/dist/bus/CommunicationBus';
import { CommunicationBusResult } from 'micronnect/dist/results/CommunicationBusResult';
import { getUser, User, UserNotFound } from "../user/util";
import { getWorkbenchUrl } from '../configuration/ioeConfiguration';

let CoreMessageBusResult: CommunicationBusResult | undefined = undefined;

export const connectToModelStream = (): CommunicationBus | undefined => {
  const ID = 'core-messages';
  if (!CoreMessageBusResult) {
    // standalone we need the proper user id, all apps
    // provide an independent login
    //
    const user = getUser();
    if((user as UserNotFound).message === undefined) {
      CoreMessageBusResult = createBus(ID, {
        streams: [
          {
            id: 'core-messages',
            server: `${getWorkbenchUrl()}/core/out/${(user as User).sessionId}`,
          },
        ],
      }, getWorkbenchUrl());
    }
  }
  return CoreMessageBusResult?.bus;
};
