import { useEffect, useState } from 'react';
import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';
import { User } from '../generated/user/util';
import { Tool } from '../types/Tool';
import { WorkbenchConfiguration } from '../types/WorkbenchConfiguration';

export interface UseToolProperties {
  user: User | undefined;
  tool?: string;
  configuration: WorkbenchConfiguration;
}

const useTool = (props: UseToolProperties) => {
  const { configuration, user, tool } = props;
  const [tools, setTools] = useState<Tool[]>([]);
  const [perspectivesLocked, setPerspectivesLocked] = useState<boolean>(true);

  useEffect(() => {
    // if we are configured to not allow persepective
    // configuration, then return no tools
    const perspectivesLockedValue = process.env.REACT_APP_PERSPECTIVES_LOCKED;
    if (perspectivesLockedValue !== undefined && perspectivesLockedValue === 'false') {
      setPerspectivesLocked(false);
    }
  }, []);

  const userId = user?.userId;

  useEffect(() => {
    if (userId && userId !== '' && (!perspectivesLocked || tool === 'Set Perspective')) {
      fetch(`${getWorkbenchUrl()}/core/tools/${userId}`).then((res) => {
        if (res.ok) {
          res.json().then((tools: Tool[]) => {
            setTools(tools);
          });
        }
      });
    }
  }, [userId, configuration, perspectivesLocked, tool]);

  return tools.find((t) => t.name === tool);
};

export default useTool;
