import { Action } from '../../types/Action';

export const SETFAILEDAPPLICATIONS = '_setfailedapplications';
export const setFailedApplications = (
  failedApplications: string[]
): Action<string[]> => {
  return {
    type: SETFAILEDAPPLICATIONS,
    payload: failedApplications,
  };
};
