import React, { useEffect, useState } from 'react';
import { VscAdd, VscSplitHorizontal, VscSplitVertical } from 'react-icons/vsc';
import { userFound, getUser, User } from '../../generated/user/util';
import useTool from '../../hooks/useTool';
import { executeContextTool } from '../../tools/Execution';
import { Container } from '../../types/Container';
import { WorkbenchConfiguration } from '../../types/WorkbenchConfiguration';

export interface ContainerSlotProperties {
  children: JSX.Element | JSX.Element[];
  container: Container;
  rootContainer: Container;
  setContainerContext: (container: Container) => void;
  setShow: (show: boolean) => void;
  configuration: WorkbenchConfiguration;
  hasView: boolean;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: ContainerSlotProperties) => {
  const [foundUser, setFoundUser] = useState<User | undefined>(undefined);
  const { children, container, setContainerContext, setShow, configuration } = props;

  const user = getUser();
  useEffect(() => {
    if (user && !foundUser) {
      if (userFound(user)) {
        setFoundUser(user as User);
      }
    }
  }, [user, foundUser]);

  const addView = useTool({
    user: foundUser,
    tool: 'Add View',
    configuration: configuration,
  });
  const disabled = addView?.options.length === 0;

  const splitVertical = useTool({
    user: foundUser,
    tool: 'Split Vertical',
    configuration: configuration,
  });

  const splitHorizontal = useTool({
    user: foundUser,
    tool: 'Split Horizontal',
    configuration: configuration,
  });

  return (
    <div className="inner-container">
      <div className="deneir-wecop-ui--container-view">
        <div className="deneir-wecop-ui--container-menu">
          {splitVertical ? (
            <button
              title={splitVertical.name}
              disabled={splitVertical.enabled !== undefined && splitVertical.enabled}
              className="deneir-wecop-ui--container-button"
              onClick={() => executeContextTool(foundUser, splitVertical, [container.containerId])}
            >
              <VscSplitVertical className="deneir-wecop-ui--container-button-icon light" />
            </button>
          ) : undefined}
          {splitHorizontal ? (
            <button
              title={splitHorizontal.name}
              disabled={splitHorizontal.enabled !== undefined && splitHorizontal.enabled}
              className="deneir-wecop-ui--container-button"
              onClick={() =>
                executeContextTool(foundUser, splitHorizontal, [container.containerId])
              }
            >
              <VscSplitHorizontal className="deneir-wecop-ui--container-button-icon light" />
            </button>
          ) : undefined}
          {addView ? (
            <button
              title="Add View"
              disabled={disabled ? true : undefined}
              className="deneir-wecop-ui--container-button"
              onClick={() => {
                setContainerContext(container);
                setShow(true);
              }}
            >
              <VscAdd
                style={{
                  color: disabled ? 'grey' : undefined,
                  width: 20,
                  height: 20,
                }}
                className={disabled ? undefined : 'deneir-wecop-ui--container-button-icon light'}
              />
            </button>
          ) : undefined}
        </div>
        {children}
      </div>
    </div>
  );
};
