import { StreamConfiguration } from './StreamConfiguration';
import { Channel } from '../channel/Channel';

export interface StreamConsumer<T> {
  configuration: StreamConfiguration;
  consumer: Worker;
  registerChannel: (
    channel: Channel<T>,
    streamName: string | undefined
  ) => void;
}

export const StreamConsumer = async <T>(
  stream: StreamConfiguration,
  workbenchUrl: string
): Promise<StreamConsumer<T>> => {
  const consumer = await fetch(
    `${workbenchUrl}/core/workers/support/Consumer/${stream.id}`
  ).then(res => {
    return res.text().then(workerContent => {
      return new Worker(URL.createObjectURL(new Blob([workerContent])));
    });
  });
  consumer.postMessage({
    channelId: stream.id,
    options: stream,
  });
  return {
    configuration: stream,
    consumer: consumer,
    registerChannel: (channel: Channel<T>, streamName: string | undefined) => {
      consumer.postMessage({
        channelId: channel.id,
        streamName: streamName,
      });
    },
  };
};
