export interface User {
  username: string;
  userId: string;
  sessionId: string;
}

export interface UserNotFound {
  message: string;
}

export const getUser = (): User | UserNotFound => {
  const userString = localStorage.getItem("user");
  if (userString) {
    try {
      const user = JSON.parse(userString);
      return user;
    } catch (e) {
      console.error("Error retrieving user.");
      console.error(e);
    }
  }
  return {
    message: "IOE User was not found.",
  };
};

export const setUser = (user: User) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const userFound = (user: User | UserNotFound): boolean => {
  if ((user as UserNotFound).message === undefined) {
    return true;
  }
  return false;
};

export const removeUser = () => {
  localStorage.removeItem("user");
};
