import usePlatformExtension from '../../extensions/usePlatformExtension';
import './styles/WorkbenchBar.css';
import ApplicationInFrame from '../views/ApplicationInFrame';

const WorkbenchBottomBar = () => {
  usePlatformExtension('actionBar::bottom-left', 'left-point');
  const { extensionServer } = usePlatformExtension('actionBar::bottom-center', 'center-point');
  usePlatformExtension('actionBar::bottom-right', 'right-point');
  let centerApp = (
    <div id="center-point" className="ioe-workbench--bottom-bar-center">
      Center App
    </div>
  );
  if (extensionServer !== '') {
    // use iframe here
    centerApp = (
      <ApplicationInFrame
        name="bottom-bar-center"
        applicationId="workbench-frontend"
        server={extensionServer}
      />
    );
  } else {
    centerApp = undefined;
  }
  return (
    <div className="ioe-workbench--bottom-bar">
      <div id="left-point" className="ioe-workbench--bottom-bar-left"></div>
      {centerApp}
      <div id="right-point" className="ioe-workbench--bottom-bar-right"></div>
    </div>
  );
};

export default WorkbenchBottomBar;
