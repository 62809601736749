import { Container } from '../../types/Container';
import ViewImpl from '../views/View';
import ContainerImpl from './Container';
import ContainerSlot from './ContainerSlot';
import { View } from '../../types/View';
import { useEffect, useState } from 'react';
import ExternalManagement from '../../external/ExternalManagement';
import { User, UserNotFound } from '../../generated/user/util';
import { useSelector } from 'react-redux';
import { WorkbenchRoot } from '../../types/WorkbenchRoot';
import { getViewToActivate } from '../../support/perspectiveUtils';
import { ConfigureActiveTab } from 'src/generated/ConfigureActiveTab';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  props: Container & {
    rootContainer: Container;
    setShow: (show: boolean) => void;
    setContainerContext: (container: Container | undefined) => void;
    openViewExternally: (user: User | UserNotFound | undefined, view: View) => void;
    externalViews: string[];
    restoreView: (name: string) => void;
    viewToActivate: ConfigureActiveTab;
  }
) => {
  const {
    rootContainer,
    setShow,
    setContainerContext,
    openViewExternally,
    externalViews,
    restoreView,
    ...rest
  } = props;
  const {
    view,
    horizontalContainers,
    verticalContainers,
    width,
    height,
    containerId,
    viewToActivate,
    showTab
  } = rest;

  const [persepectivesLocked, setPerspectivesLocked] = useState<boolean>(true);
  const horizontal = horizontalContainers && horizontalContainers.length > 0;
  const configuration = useSelector(
    (state: WorkbenchRoot) => state.workbench.WorkbenchConfiguration
  );
  const [activeView, setActiveView] = useState<{ active: string; timestamp: Date; name: string }>(
    view
      ? { active: view.viewId, timestamp: new Date(), name: view.name }
      : { active: '', timestamp: new Date(), name: '' }
  );
  const [currentViewToActivate, setCurrentViewToActivate] = useState<ConfigureActiveTab>();

  useEffect(() => {
    // if we are configured to not allow persepective
    // configuration, then return no tools
    const perspectivesLockedValue = process.env.REACT_APP_PERSPECTIVES_LOCKED;
    if (perspectivesLockedValue !== undefined && perspectivesLockedValue === 'false') {
      setPerspectivesLocked(false);
    }
  }, []);

  useEffect(() => {
    if (viewToActivate) {
      // this is from a signal, we want to keep local
      // state to prevent overwriting user tab changes
      // if combined viewToActivate will always be true
      // and will overwrite any manual tab change
      setCurrentViewToActivate(viewToActivate);
    }
  }, [viewToActivate]);

  useEffect(() => {
    if (currentViewToActivate && activeView.name !== currentViewToActivate.viewName) {
      // known limitation all views matching the
      // requested name will be activated, in other
      // words there is no way to specify a specific
      // container at this time
      //
      // See if this container has the view
      const view = getViewToActivate(currentViewToActivate.viewName, rest);
      if (view) {
        setActiveView({
          active: view.viewId,
          timestamp: currentViewToActivate.timestamp,
          name: view.name,
        });
      }
    }
  }, [activeView.name, currentViewToActivate, rest]);

  const handleTabSelection = (tab: string) => {
    const view = getViewToActivate(tab, rest);
    // clear currentViewToActivate to prevent overwriting this selection
    setCurrentViewToActivate(undefined);
    setActiveView({ active: tab, timestamp: new Date(), name: view.name });
  };

  // width may be configured as a double, for percentage or a string
  // for css specific sizes like 100px or 100%
  let widthToSet: string | number = width;
  let heightToSet: string | number = height;
  if (width) {
    if (typeof width === 'string' && (width.includes('px') || width.includes('%'))) {
      widthToSet = width;
    } else {
      widthToSet = `${width}%`;
    }
  }
  if (height) {
    if (typeof height === 'string' && (height.includes('px') || height.includes('%'))) {
      heightToSet = height;
    } else {
      heightToSet = `${height}%`;
    }
  }

  return (
    <div
      className={horizontal ? 'horizontalContainer' : 'verticalContainer'}
      style={{
        width: widthToSet ? `${widthToSet}` : undefined,
        height: heightToSet ? `${heightToSet}` : undefined,
      }}
    >
      {view ? (
        <ContainerSlot
          container={rest}
          rootContainer={rootContainer}
          setContainerContext={setContainerContext}
          setShow={setShow}
          configuration={configuration}
          hasView={true}
        >
          <ViewImpl
            view={view}
            containerId={containerId}
            configuration={configuration}
            openViewExternally={openViewExternally}
            activeTab={activeView.active}
            selectTabCallback={(tab) => handleTabSelection(tab)}
            showTab={showTab}
          />
        </ContainerSlot>
      ) : horizontalContainers.length > 0 ? (
        horizontalContainers.map((c) => (
          <ContainerImpl
            rootContainer={rootContainer}
            key={c.containerId}
            {...c}
            setShow={setShow}
            setContainerContext={setContainerContext}
            openViewExternally={openViewExternally}
            externalViews={externalViews}
            restoreView={restoreView}
            viewToActivate={viewToActivate}
          />
        ))
      ) : verticalContainers.length > 0 ? (
        verticalContainers.map((c) => (
          <ContainerImpl
            rootContainer={rootContainer}
            key={c.containerId}
            {...c}
            setShow={setShow}
            setContainerContext={setContainerContext}
            openViewExternally={openViewExternally}
            externalViews={externalViews}
            restoreView={restoreView}
            viewToActivate={viewToActivate}
          />
        ))
      ) : (
        <ContainerSlot
          container={rest}
          rootContainer={rootContainer}
          setContainerContext={setContainerContext}
          setShow={setShow}
          configuration={configuration}
          hasView={false}
        >
          {persepectivesLocked ? (
            <ExternalManagement views={externalViews} restoreView={restoreView} />
          ) : (
            <div className="deneir-wecop-ui--container-no-view">
              Use the '+' button to add a view.
            </div>
          )}
        </ContainerSlot>
      )}
    </div>
  );
};
