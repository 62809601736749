import { useEffect } from 'react';
import { getWorkbenchUrl } from '../generated/configuration/ioeConfiguration';
import { User, userFound, UserNotFound } from '../generated/user/util';

/**
 * Provide access to the IOE extension registry
 */
const useAvailableStreams = (user: User | UserNotFound | undefined) => {
  useEffect(() => {
    if (user && userFound(user)) {
      const ioeWindow = window as Record<string, any>;
      if (!ioeWindow.ioe) {
        ioeWindow.ioe = {
          initialized: true,
        };
      }
      ioeWindow.ioe.streams = {
        getAvailable: async (systemType: string) => {
          return fetch(
            `${getWorkbenchUrl()}/core/data/${
              (user as User).sessionId
            }/streams?systemType=${systemType}`
          ).then((res) => {
            if (res.status === 404) {
              return {};
            }
            if (res.ok) {
              return res.json();
            }
          });
        },
      };
    }
  }, [user]);
};
export default useAvailableStreams;
