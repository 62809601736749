import { ConfigureView } from '../generated/ConfigureView';
import { ViewPosition } from '../generated/ViewPosition';
import { Container } from '../types/Container';
import { View } from '../types/View';
import { WorkbenchConfiguration } from '../types/WorkbenchConfiguration';

export const findContainerAndView = (
  viewName: string,
  container: Container
): [Container, View, View, View] => {
  let before = findViewBeforeOrAfter('before', viewName, container);
  let view: View = undefined;
  if (!before) {
    view = container.view;
  } else {
    view = before.nextView;
  }
  if (view) {
    return [container, view, before, view.nextView];
  } else {
    for (let nested of container.horizontalContainers) {
      const found = findContainerAndView(viewName, nested);
      if (found) {
        return found;
      }
    }
    for (let nested of container.verticalContainers) {
      const found = findContainerAndView(viewName, nested);
      if (found) {
        return found;
      }
    }
  }
};

export const findContainerAndViewInPerspective = (
  nextTo: string,
  configuration: WorkbenchConfiguration
): [Container, View, View, View] => {
  return findContainerAndView(nextTo, configuration.activePerspective.rootContainer);
};

export const findViewBeforeOrAfter = (
  searchType: 'before' | 'after',
  view: string,
  container: Container
): View => {
  let after = undefined;
  let next = container.view;
  let before = undefined;
  while (next) {
    if (next.name === view) {
      after = next.nextView;
      break;
    }
    before = next;
    next = before.nextView;
  }
  return searchType === 'before' ? before : after;
};

export const addOrRemoveView = (
  configuration: WorkbenchConfiguration,
  signal: ConfigureView,
  availableViews: View[]
) => {
  if (signal.shown) {
    // find the view in the available views and place it next
    // to the given view in the same container
    const viewToShow = availableViews.find((v) => v.name === signal.viewName);
    if (viewToShow) {
      const nextTo = signal.nextToView;
      const result = findContainerAndViewInPerspective(nextTo, configuration);
      if (result) {
        const [container, view, before, after] = result;
        if (signal.position === ViewPosition.After) {
          if (after?.name !== signal.viewName) {
            view.nextView = viewToShow;
            if (after) {
              viewToShow.nextView = after;
            }
          }
        } else if (signal.position === ViewPosition.Before) {
          let skip = false;
          if (before) {
            if (before.name !== signal.viewName) {
              before.nextView = viewToShow;
            } else {
              // skip processing as we are already before
              skip = true;
            }
          } else {
            container.view = viewToShow;
          }
          if (!skip) {
            viewToShow.nextView = view;
          }
        }
      }
    } else {
      console.error('Unexpected request, a view request cannot be made on a non-existent view.');
      return;
    }
  } else {
    // find the view in the perspective and remove it
    // This support only works for secondary tabs, meaning
    // we will not close the last view in a container
    const result = findContainerAndViewInPerspective(signal.viewName, configuration);
    if (result) {
      const [container, view] = result;
      if (container.view.name === view.name) {
        // we must have a next view
        if (view.nextView) {
          container.view = view.nextView;
        } else {
          console.error(
            'Unexpected configuration, a request to remove a view must not be made for the last view in a container.'
          );
          return;
        }
      } else {
        // we need to find the view in the tab list
        const viewBefore = findViewBeforeOrAfter('before', view.name, container);
        viewBefore.nextView = view.nextView;
      }
    } else {
      console.warn('Attempt to remove view that is not currently shown.');
    }
  }
};

export const getViewToActivate = (viewIdOrName: string, container: Container): View | undefined => {
  let view = undefined;
  let nextView = container.view;
  while (nextView) {
    if (nextView.name === viewIdOrName || nextView.viewId === viewIdOrName) {
      view = nextView;
      break;
    }
    nextView = nextView.nextView;
  }
  return view;
};
